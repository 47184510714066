import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home.component";
import {CommonModule} from "@angular/common";
import {GraphiquesComponent} from "./components/graphiques/graphiques.component";
import {QualiteTensionsComponent} from "./components/qualite-tensions/qualite-tensions.component";
import {GeographiqueComponent} from "./components/geographique/geographique.component";
import {ConsommationComponent} from "./components/consommation/consommation.component";
import {CoupureComponent} from "./components/coupure/coupure.component";
import {
  ConsommationVueGraphiqueComponent
} from "./components/consommation-vue-graphique/consommation-vue-graphique.component";
import {CoupureVueGraphiqueComponent} from "./components/coupure-vue-graphique/coupure-vue-graphique.component";
import {EvenementsComponent} from "./components/evenements/evenements.component";
import {AbonnementsComponent} from "./components/abonnements/abonnements.component";

const routes: Routes = [
  { path: '', component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'geographique',
      },
      { path: 'geographique', component: GeographiqueComponent,
        children: [
          {
            path: '',
            redirectTo: 'consommation',
          },
          {
            path:'tensions', component: QualiteTensionsComponent
          },
          {
            path:'consommation', component: ConsommationComponent
          },
          {
            path:'coupure', component: CoupureComponent
          },
          {
            path:'abonnement', component: AbonnementsComponent
          },
            {
                path:'evenements', component: EvenementsComponent,
            }
        ]
      },
      { path: 'graphique', component: GraphiquesComponent,
        children: [
          {
            path: '',
            redirectTo: 'consommation',
          },
          {
            path:'tensions', component: CoupureVueGraphiqueComponent
          },
          {
            path:'consommation', component: ConsommationVueGraphiqueComponent
          },
          {
            path:'coupure', component: CoupureVueGraphiqueComponent
          },
        ]
      }
    ]
  },

];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
