import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";


@Component({
  selector: 'app-histogramme-triphase',
  templateUrl: './histogramme-triphase.component.html',
  styleUrls: ['./histogramme-triphase.component.css']
})
export class HistogrammeTriphaseComponent {



  Highcharts = Highcharts;

  _data;

  chartoption: any

  chartUpdateFlag;

  @Input() set data(value){
    this._data = value;

    this.chartoption={
      chart: {
        renderTo: 'container',
        type: 'column',
        backgroundColor: 'transparent',
      },

      title:{
        text: '',
        margin: 50,
        style: {
          color: '#FFF',
          fontWeight: 'bold',
        }

      },

      tooltip: {
        shared: true,
      },

      plotOptions: {
        column: {
          stacking: 'normal',
          grouping: false,
        }
      },

      legend: {
        enabled: true, itemStyle: {
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.6vw',
        },
      },

      credits: {
        enabled: false,
      },

      colors: ['#FF4F46', '#DEDFDE', '#8F77FA'],

      xAxis:{
        categories: this._data.categories,
        lineWidth: 1,
        labels: {
          style: {
            color: '#FFF',
            fontSize:  '0.8rem'
          }
        },
      },
      yAxis: {
        gridLineWidth: 0,
        title: '',
        lineWidth: 1,
        labels: {
          style: {
            color: '#FFF',
            fontSize:  '0.8rem'
          }
        },
      },

      series: [{
        name: 'coupure longue',
        data: this._data.data[2]

      }, {
        name: 'coupure brève',
        data: this._data.data[1]
      }, {
        name: 'coupure très brève',
        stacking: 'normal',
        data: this._data.data[0]
      }]
    }
    this.chartUpdateFlag = true;
  }

  get data(){
    return this._data;
  }

}
