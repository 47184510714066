import { Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { secondsToDhmsFormatter } from "../../../../../utils/date-formatting";

function pad(hours: number) {
  return hours < 10 ? `0${hours}` : `${hours}`;

}

@Component({
  selector: 'app-get-statistique',
  templateUrl: './get-statistique.component.html',
  styleUrls: ['./get-statistique.component.css']
})
export class GetStatistiqueComponent {
  chartUpdateFlag: any;


  Highcharts = Highcharts;

  _type;
  _salesData;
chartOption: any;



  @Input() set salesData(value){

    this._salesData = value;
     this.chartOption = {
          chart: {
            backgroundColor: 'transparent',
            type: 'column',
            inverted: true,
          },
          credits: {

            enabled: false,


          },
          colors: ['rgba(1, 222, 253, 0.2)'],
          title:{
            text: '',
            margin: 50,

            style: {
              color: '#FFF',
              fontWeight: 'bold',
            }

          },

          xAxis:{

            categories: ["SAIDI", "SAIFI", "CAIDI", "CAIFI"],
            lineWidth: 0,
            labels: {
              style: {
                color: '#FFF',
                fontSize:  '0.5rem'
              }
            },
          },
          yAxis: {
            visible: false,
          },
          plotOptions: {
            series: {
              borderColor: 'rgba(26, 53, 221, 0.2)'
            },
            column: {
              grouping: false,
              pointPadding: 0.1,
              borderWidth: 0,
              groupPadding: 0,
            }

          },
          legend: {
            enabled: false
          },
          series: [
            {
              minPointLength: 50,
              dataLabels:{
                shadow: false,
                enabled: true,
                borderWidth: 0,
                inside: false,
                align: 'left',
                allowDecimals: false,
                color: '#fff',
                style: {
                  fontSize: '0.4vw',
                  fontWeight: 'bold',
                  color: 'white',
                  textShadow: false
                },
                formatter: function() {
                  if(this.point.index === 0 || this.point.index === 2){
                    return  secondsToDhmsFormatter(this.y)
                  }else return  this.y;
                }
              },
              name: '',
              data: [this._salesData[0], this._salesData[1],this._salesData[2],this._salesData[3]],


            },

          ]
     }

      this.chartUpdateFlag = true;
    }


  get salesData(){
    return this._salesData;
  }

}
