import { Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { PIE_CHART_OPTIONS } from "../../../../../utils/charts/pie-chart-option";
import * as _ from 'lodash';

@Component({
  selector: 'app-repartition-conso-calendrier-horair',
  templateUrl: './repartition-conso-calendrier-horair.component.html',
  styleUrls: ['./repartition-conso-calendrier-horair.component.css']
})
export class RepartitionConsoCalendrierHorairComponent {

  _colors: any;

  Highcharts = Highcharts;
  pieChartData =  _.cloneDeep(PIE_CHART_OPTIONS);
  pieUpdateFlag = false;


  _data: any;

  @Input() set colors(value){
    this._colors = value;
    this.pieChartData.plotOptions.pie.colors = this.colors;
  }

  get colors(){
    return this._colors;
  }

  @Input() set data(value){
    this.pieChartData.series[0].data = [];
   if(value.length > 0){
     this.pieChartData.title.text = 'Repatition Conso';
     this.pieChartData.legend.enabled = false
     this.pieChartData.plotOptions.pie.dataLabels.enabled = true
     this.pieChartData.title.x = 6;

     value[0] ? this.pieChartData.series[0].data.push({name: 'week end',y:(Number(value[0]))}) : '';
     value[1] ? this.pieChartData.series[0].data.push({name: 'ferie',y:(Number(value[1]))}) : '';
     value[2] ? this.pieChartData.series[0].data.push({name: 'jour ouvrable',y:(Number(value[2]))}) : '';
     this.pieUpdateFlag = true;
   }
  }

  get data(){
    return this._data;
  }
}
