import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-conso-par-agence',
  templateUrl: './conso-par-agence.component.html',
  styleUrls: ['./conso-par-agence.component.css']
})
export class ConsoParAgenceComponent {

  _data;

  @Input() set data(value){
    if(value){
      this._data = value;
    }
  }

  get data(){
    return this._data;
  }
}
