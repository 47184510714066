<ngx-loading
        [show]="loading"
></ngx-loading>
<app-carte-tension
        *ngIf="postes && meters && legendPoste && legendTension && dateOfData"
        [compteurs]="{postes: postes, meters: meters, type: type, legendPoste: legendPoste, legendTension: legendTension, displayLegend: true}"
        [allMeters]="{postes: postes, meters: meters, type: type, legendPoste: legendPoste, legendTension: legendTension, displayLegend: true}"
        [titre]="'Qualité de service'"
        [dateOfData]="dateOfData"
></app-carte-tension>
