export const QUALIMETRIE_DATA = {
    chart: {
        backgroundColor: 'transparent',
    },
    colors: ['#F5620C'],
    title: {
        text: 'Courbe',
        style: {color: 'transparent',
        }

    },
    legend: {
        enabled: true,
        itemStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.5vw',
        },
    },
    credits: {
        enabled: false,
    },
 xAxis: {
        lineWidth: 5,
        categories: [],
        labels: {
            style: {
                color: 'white',
                fontSize:  '0.7vw'
            }
        },
    },
    yAxis:
    {
        gridLineWidth: 0,
        title: '',
        min: 100,
        lineWidth: 5,
        labels: {
            style: {
                fontSize:  '1.2vw',
                color: 'white',
            }
        },
        plotLines: [
            {
                color: '#F98380',
                width: 2,
                dashStyle: 'longdash',
                value: null,
                zIndex: 5,
                label: {
                    text: 'Zone de Baisse de tension',
                    align: 'center',
                    y: 60,
                    style: {
                        color: '#F98380',
                        fontSize:  '1.2vw',
                        fontWeight: ''
                    }
                },
            },{
                color: '#70FF00',
                width: 2,
                dashStyle: 'longdash',
                value: null,
                zIndex: 5,
            },{
                color: '#F98380',
                width: 2,
                dashStyle: 'longdash',
                value: null,
                zIndex: 5,
                label: {
                    text: 'Zone de Surtension',
                    y: -60,
                    align: 'center',
                    style: {
                        color: '#F98380',
                        fontSize:  '1.2vw',
                        fontWeight: '',
                    }
                }
            },
            {
                color: '#F98380',
                width: 2,
                dashStyle: 'longdash',
                value: null,
                zIndex: 5,
                label: {
                    text: '',
                    align: 'right',
                    style: {
                        color: '#F98380',
                        fontSize:  '1vw',
                        fontWeight: 'bold'
                    }
                },
            },
            {
                color: 'transparent',
                width: 2,
                dashStyle: 'longdash',
                value: null,
                zIndex: 5,
                label: {
                    text: '',
                    align: 'right',
                    style: {
                        color: '#F98380',
                        fontSize:  '1vw',
                        fontWeight: 'bold'
                    }
                },
            }
        ]
    },



    series: [
        {
            lineWidth: 4,
            marker: {
                enabled: false,
            },
            color: '#D0C000',
            //type: 'areaspline',
            name: 'Phase 1',
            data: []
        },
        {
            //fillOpacity: 0.3,
            lineWidth: 4,
            marker: {
                enabled: false,
            },
            color: '#84E6F9',
            //type: 'areaspline',
            name: 'Phase 2',
            data: []
        },
        {
            lineWidth: 4,
            marker: {
                enabled: false,
            },
            color: '#D75607',
            //type: 'areaspline',
            name: 'Phase 3',
            data: []
        },
    ]
};

export const HISTO_PAIR = {
    chart: {
        type: 'column',
        backgroundColor: 'transparent',
    },
    title: {
            text: '',
            style: {color: 'transparent',
            }

        },

    style: {
            color: '#FFF',
            fontWeight: 'bold',
        },
    credits: {
        enabled: false,
    },
    colors: ['rgba(189, 87, 65, 1)', '#01ABAE'],
    legend: {
        enabled: true,
        verticalAlign: 'bottom',
        itemStyle:{
            fontSize:'0.8vw',
            color: '#FFF',
        }

    },

    xAxis:{
        categories: [],
        lineWidth: 1,
        labels: {
            style: {
                fontSize: '0.5vw',
                color: "#fff",
            }
        }
    },

    yAxis: [
    {
        labels:{
            style:{
                color:'#FFF'
            },
        },
        title: {
            text: 'kWh',
            style: {
                color: '#FFF',
                fontSize: '0.5vw',
            }
        },
        gridLineWidth: 0,
        lineWidth: 1,
    },
    {
        labels:{
            style:{
                color:'#FFF'
            },
        },
        title: {
            text: 'MRU',
            style: {
                color: '#FFF',
                fontSize: '0.5vw',
            }
        },
        gridLineWidth: 0,
        lineWidth: 1,
        opposite: true,
    }],


    series: [
        {
            yAxis: 0,
            name: "Energies (kWh)",
            data: [],
            borderColor: '',
        },
        {
            yAxis: 1,
            name: "Montants achat (MRU)",
            data: [],
            borderColor: '',
        }
    ]
};

export const HISTO_PAIR_Percent = {...HISTO_PAIR,
    yAxis:
        {
            labels:{
                style:{
                    color:'#FFF'
                },
            },
            title: {
                text: 'kWh',
                style: {
                    color: '#FFF',
                    fontSize: '0.5vw',
                }
            },
            gridLineWidth: 0,
            lineWidth: 1,
        },


    series: [
        {
            name: "Energies (kWh)",
            data: [],
            borderColor: '',
        },
        {
            name: "Montants achat (MRU)",
            data: [],
            borderColor: '',
        }
    ],
    tooltip: {
        formatter: function() {
            return '<span style="color:' + this.series.color + '">&#9679;</span> <b>' + this.series.name + '</b>: ' + this.y + '%';
        }
    },};
