
export function secondsToDhmsFormatter(params: any): any {
    if (params) {
        const seconds = params;
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor((seconds % (3600 * 24)) / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);

        return `${h}:${m}:${s}`;
    }
    return '-';
}
