import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import {HISTOGRAMME_OPTIONS} from "../../../../../utils/charts/column-courbe-chart-options";
import * as _ from 'lodash';

@Component({
  selector: 'app-histogrammevertical-courbe',
  templateUrl: './histogrammevertical-courbe.component.html',
  styleUrls: ['./histogrammevertical-courbe.component.css']
})
export class HistogrammeverticalCourbeComponent {
  highcharts = Highcharts;
  chartOptions =  _.cloneDeep(HISTOGRAMME_OPTIONS);
  _data;
  chartUpdateFlag = false;

  @Input() set data(value){
    this.chartOptions.series[0].data = [];
    this._data =  value;
    const xAxis = this.chartOptions.xAxis[0];

    xAxis.categories = [];
    this.chartOptions.series[0].data = [];
    this.chartOptions.series[1].data = [];

    this._data.forEach((element) => {
        xAxis.categories.push(this.extractYearMonth(element[0]));
        this.chartOptions.series[0].data.push(Number(element[1]));
        this.chartOptions.series[1].data.push(Number(element[2]));
    });

    this.chartUpdateFlag = true;
  }

  get data() {
    return this._data;
  }

  extractYearMonth(date: string): string {
    const [year, month] = date.split('-');
    return `${month}-${year}`;
  }

}