import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Service intercepteur qui ajoute le token de l'utilisateur au niveau de l'entete de chaque requete
 */
@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ajout de l'entete Authorization si disponible
    const currentUser = JSON.parse(localStorage.getItem('userToken'));
    if (currentUser && currentUser.access_token) {
      if (!request.headers.get('Authorization')) {
        // eslint-disable-next-line no-param-reassign
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.access_token}`,
          },
        });
      }
    }
    return next.handle(request);
  }
}
