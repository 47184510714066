import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";

/**
 * Intercepteur qui déconnecte automatiquement l'utilisateur
 * dès qu'on a une réponse 401(Unauthorized) de la part d'un serveur
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 401 && err.error.code !== 9120) {
                    this.authService.logout();
                    this.router.navigate(['/login']);
                }
                const error = err;
                return throwError(error);
            }),
        );
    }
}
