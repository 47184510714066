<div class="table-responsive">
    <table class="table">
        <thead>
        <tr class="left">
            <th scope="col">Date</th>
            <th scope="col">Durée [jours, hh:mn:ss]</th>
            <th scope="col">Type coupure</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let info of data.coupures">
            <td> {{info.date_debut_coupure | date: "dd MMMM yyyy hh:mm"}} </td>
            <td>{{info.duree_coupure}}</td>
            <td>{{info.type_coupure}}</td>
        </tr>
        </tbody>
    </table>
    <label class="nodata" *ngIf="data.coupures.length === 0">Pas de Données</label>
</div>
