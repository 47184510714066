import {Component, Input, OnInit} from '@angular/core';
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-evenements',
  templateUrl: './evenements.component.html',
})
export class EvenementsComponent implements  OnInit{

    loading = false;

    type = 'evenements';

    periode = 'jour';

    tempSubscription!: Subscription;

    legend;

    compteurs;
    @Input() display = true;

    netSubs!: Subscription;
    date: any;
    first = true;
    perimeterCode: any;
    perimeterType: any;
    dateOfData: string;
    typeEvent: string = 'SPF';
    color = 'global';
    dateDebut;
    dateFin;

    constructor(
        private service: MainScreenService,
        private store: Store<any>,
        private datePipe :DatePipe
        ) {}

    ngOnInit(): void {
        this.tempSubscription = this.store
            ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
        this.netSubs = this.store.pipe(select('header_store'))?.subscribe(this.preSetAgency.bind(this));
    }

    preSetDate(state: any) {
        this.date= state.selected_date;
        this.periode = state.period;
        this.dateDebut = state.start_date;
        this.dateFin = state.end_date;
        this.getDateEvents();
        if(!this.first){
            this.getEventsData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin, this.typeEvent, this.color);
        }
    }


    preSetAgency(state: any) {
        this.perimeterType = state.type;
        this.perimeterCode = state.code;
        this.getEventsData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin, this.typeEvent, this.color);
        this.first = false;
    }


    getEventsData(code, type, date, date_debut, date_fin, typeEvent, color) {
        this.loading = true;
        this.service.getEvents(type, code, this.periode, date, date_debut, date_fin, typeEvent, color).subscribe(
            (data: any) => {
                this.loading = false;
                this.compteurs = {compteurs: data, type: this.type, displayLegend: this.display};

            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getDateEvents(){
        switch (this.periode) {
            case 'intervalle' :
                this.dateOfData = `Date: ${this.datePipe.transform(new Date(this.dateDebut), "dd/MM/yy")} au ${this.datePipe.transform(new Date(this.dateFin), "dd/MM/yy")}`
                break;
            case 'mois':
                this.dateOfData  = `Date: ${this.datePipe.transform(new Date(this.date), "MMMM yyyy", "fr-FR")}`;
                break;
            case '30_derniers_jours':
                this.dateOfData =`30 derniers jours`
                break;
            default:
                this.dateOfData = `Date: ${this.datePipe.transform(new Date(this.date), "dd/MM/yy")}`;
                break;
        }
    }

    filter($event){
        this.typeEvent = $event.type;
        this.color = $event.color;
        this.getEventsData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin, this.typeEvent, this.color);

    }

}
