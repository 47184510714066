
export const SALES_DATA = {
    chart: {
        backgroundColor: 'transparent',
        type: 'column',
        inverted: true,
    },
    credits: {

        enabled: false,


    },
    colors: ['rgba(1, 222, 253, 0.2)'],
    title:{
        text: '',
        margin: 50,

        style: {
            color: '#FFF',
            fontWeight: 'bold',
        }

    },

    xAxis:{

        categories: [],
        lineWidth: 0,
        labels: {
            style: {
                color: '#FFF',
                fontSize:  '0.5rem'
            }
        },
    },
    yAxis: {
        visible: false,
    },
    plotOptions: {
        series: {
            borderColor: 'rgba(26, 53, 221, 0.2)'
        },
        column: {
            grouping: false,
            pointPadding: 0.1,
            borderWidth: 0,
            groupPadding: 0,
        }
    },
    legend: {
        enabled: true, itemStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '0.8vw',
        },
    },
    series: [
        {
            minPointLength: 50,
            dataLabels:{
                shadow: false,
                enabled: true,
                borderWidth: 0,
                inside: true,
                align: 'left',
                allowDecimals: false,
                color: '#fff',
                style: {
                    fontSize: '0.4vw',
                    fontWeight: 'bold',
                    color: 'white',
                    textShadow: false
                },
            },
            name: '',
            data: [],


        },

    ]
}
