import * as TempNavigationActions from '../actions/temporal-navigation.actions';
import 'dayjs/locale/fr';
import * as dayjs from "dayjs";

const todayDate = new Date();
const yesterdayDate = new Date();
const todaysDayOfMonth = todayDate.getDate();
yesterdayDate.setDate(todaysDayOfMonth - 1);

const initialState = {
    selected_date: dayjs(yesterdayDate).format('YYYY-MM-DD'),
    period: 'jour'
};

export function tempNavReducer(state = initialState, action: TempNavigationActions.Actions) {
    if (action.type === TempNavigationActions.SET_TEMPORAL_NAVIGATION_DATE) {
        return {
            ...state,
            selected_date: action.payload.date,
            period: action.payload.period,
            start_date: action.payload.start,
            end_date: action.payload.end,

        };
    }
    return state;
}
