import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import {COURBE_UNIQUE_DATA} from "../../../../../utils/charts/courbe-unique-option";
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-courbe-unique',
  templateUrl: './courbe-unique.component.html',
  styleUrls: ['./courbe-unique.component.scss']
})
export class CourbeUniqueComponent {

  chartOptions: any = _.cloneDeep(COURBE_UNIQUE_DATA);

  Highcharts = Highcharts;

  recouvrements;

  _data;

  remboursements: any;

  clients = '';

  recouvrementTotal = '';

  chartUpdateFlag = false;

  @Input() set data(value){
    moment.locale('fr');
    this.chartOptions.xAxis[0].categories = [];
    this.chartOptions.series[0].data = [];
    this.chartOptions.series[1].data = [];
    if(value){
      this._data = value;
      if(this._data[0]?.length > 0){
        this.recouvrements = JSON.parse(this._data[0][1]);
        this.clients = this._data[0][4];
        this.recouvrementTotal = this._data[0][3];
      }
      const xAxis = this.chartOptions.xAxis[0];
      xAxis.categories = [];

      if(this.recouvrements?.length > 0){
        this.recouvrements.forEach((element: { montant: any; date: moment.MomentInput; }) => {
          this.chartOptions.series[0].data.push(element.montant);
          if(moment(element.date, 'YYYY-MM-DD', true).isValid()){
            xAxis.categories.push(moment(element.date).format('DD-MM'));
          }else {
            this.chartOptions.xAxis[0].categories.push(element.date);
          }
        });
      }

      if(this._data[0]?.length > 0 ){
        this.remboursements = JSON.parse(this._data[0][2]);

        this.remboursements.forEach((element: { montant: any; }) => {
          this.chartOptions.series[1].data.push(element.montant);
        });
      }



      this.chartUpdateFlag = true;
    }
  }

  get data(){
    return this._data;
  }

}
