import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login-forgot',
  templateUrl: './login-forgot.component.html',
  styleUrls: ['./login-forgot.component.css']
})
export class LoginForgotComponent implements OnInit{

  loginForm: FormGroup;

  loginFormFields = {
    email: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    stayConnected: false,
  };

  isLoading = false;

    constructor(
        private fb: FormBuilder,
    ) {}

  onSubmit() {}

  get email() {
    return this.loginForm.get('email');
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group(this.loginFormFields);
  }

}
