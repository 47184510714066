import * as Highcharts from 'highcharts';
export  const HISTOGRAMME_OPTIONS = {
    chart: {
        backgroundColor: 'transparent',
    },

    colors: ['#DF2C00', '#D0C000'],

    title:{
        text: '',
        margin: 50,

        style: {
            color: '#FFF',
            fontWeight: 'bold',
        }
    },
    credits: {
        enabled: false,
    },
    legend: {
        marginTop: '-50px',
        itemStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '0.8vw',
        },
    },
    tooltip: {
        shared: true
    },
    xAxis: [{
        categories: [],
        lineWidth: 1,
        labels: {
            style: {
                color: '#FFF',
                fontSize:  '0.6rem'
            }
        },
    }],

    yAxis: [
    {
        labels: {
            style: {
                color: '#FFF'
            }
        },
        title: {
            text: 'MRU',
            style: {
                color: '#FFF',
                fontSize: '0.5vw',
            }
        },
        gridLineWidth: 0,
        lineWidth: 1,
    }, {
        title: {
            text: 'kWh',
            style: {
                color: '#FFF',
                fontSize: '0.5vw',
            }
        },
        labels: {
            style: {
                color: '#FFF'
            }
        },
        gridLineWidth: 0,
        lineWidth: 1,
        opposite: true,
    }],

    plotOptions: {
        areaspline: {
            fillOpacity: 0.2,
        }
    },

    series: [
        {
            name: 'kWh',
            type: 'column',
            yAxis: 1,
            data: [],
            tooltip: {
                valueSuffix: 'kWh'
            }

        }, {
            name: 'MRU',
            type: 'areaspline',
            yAxis: 0,
            marker: {
                enabled: true,
                symbol: "circle",
                radius: 2
            },
            data: [],
            tooltip: {
                valueSuffix: 'MRU'
            }
        }]
}
