import {Component, ComponentFactoryResolver, EventEmitter, Injector, Input, Output} from '@angular/core';
import * as L from "leaflet";
import 'leaflet.markercluster';
import {createCustomPopup} from "../../../../../utils/marker";
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {NbToastrService} from "@nebular/theme";
import {configNotification} from "../../../../../utils/variables";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-carte',
  templateUrl: './carte.component.html',
  styleUrls: ['./carte.component.scss']
})
export class CarteComponent{

    tempSubscription!: Subscription;
    date;
    @Output() filter = new EventEmitter<any>();
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector, private service: MainScreenService,
              private toastrService: NbToastrService,
              private datePipe :DatePipe,
              private store: Store<any>) {
      this.tempSubscription = this.store
          ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
  }

  icons: any;

  channel: any;

  _compteurs: any;

  _allMeters;

  type;

  point;

  @Input() isEvent = false;

  @Input() set allMeters(value) {
    this._allMeters = value.compteurs;
  }

    @Input() titre = '';

    @Input() dateOfData = '';

  mapOptions = {
    displayLegeng: true,
    zoomControl: false,
    layers: [
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxNativeZoom: 18,
        maxZoom: 18,
        minZoom: 10,
        attribution: '<a href="https://www.akilee-by-ines.com">Akilee</a>',
      }),

    ],
    zoom: 12,
    center: L.latLng(18.1, -15.95)
  };

  data;

  markerClusterData: L.CircleMarker[] = [];

  markerClusterOptions: L.MarkerClusterGroupOptions = {
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    spiderfyOnMaxZoom: true,
    removeOutsideVisibleBounds: true,
    animate: true,
    disableClusteringAtZoom: 3,
  };
  @Input() set compteurs(value : any){
   if(value){
     this._compteurs = value.compteurs.coordonnees;
     this.legend = value.compteurs.legend;
     this.type = value.type
       this.displayLeg = value.displayLegend;
       this.initMap();
   }
  }

  get compteurs(){
    return this._compteurs;
  }

    preSetDate(state: any) {
      switch (state.period ) {
          case 'intervalle':
              this.date =  state.end_date;
              break;
          case 'mois':
              let lastDate = new Date(new Date(state.selected_date).getFullYear(), new Date(state.selected_date).getMonth()+1, 0);
              this.date =   this.datePipe.transform(lastDate, "yyyy-MM-dd");
              break;
          default:
              this.date =   this.date = state.selected_date;
              break;
      }
    }

  get allMeters(){
    return this._allMeters;
  }

  loading = false;

  legend: any;

  displayLeg;

  /* Filtre la carte au clique sur la légende */
  refresh($event){
   if($event.length === 0)
   {
     this.compteurs = {compteurs: { coordonnees:this.allMeters.coordonnees, legend: this.legend}, type: this.type, displayLegend: this.displayLeg};
   } else if($event.type){
       this.filter.emit($event);
   }
   else {
       let meters = this.allMeters.coordonnees;
       let filteredMeters = [];
       let listMeters = [];
       $event.forEach((el) => {
           filteredMeters = meters.filter((element) => element[2] === el.couleur);
          listMeters =  listMeters.concat(filteredMeters);
       });
       this.compteurs = {compteurs: { coordonnees: listMeters, legend: this.legend}, type: this.type, displayLegend: this.displayLeg};

   }
  }

  /* Initialise la carte */
  initMap() {
    this.markerClusterData = this.getMarker(this.compteurs, this.type);
  }

  getData(i, point){
      switch (this.type) {
        case 'tension':
        this.getTensionsData(i, point);
        break;
        case 'evenements':
            this.getEventsDetails(i, point);
            break;
        case 'consommation':
            this.getConsoData(i, point);
            break;
        default:
          this.getConsoData(i, point);
          break;
    }
  }

  getTensionsData(i: number, selected :any){
    this.loading = true;
    this.service.getDetailsTension(selected[3], this.date).subscribe(
        (data) => {
          this.data= data;
          this.loading = false;
          this.markerClusterData[i]?.bindPopup(() => createCustomPopup(this.componentFactoryResolver, this.injector , selected, this.type, this.data), {});
          this.markerClusterData[i]?.openPopup();
        },
        (error) => {
            this.toastrService.danger(
                `Details : Données non recupérées`,
                'Une erreur est survenue.',
                configNotification
            );
          this.loading = false;
        },
    );
  }

    getConsoData(i: number, selected :any){
        this.loading = true;
        this.service.getDetailsConso(selected[3], this.date).subscribe(
            (data) => {
                this.data= data;
                this.loading = false;
                this.markerClusterData[i]?.bindPopup(() => createCustomPopup(this.componentFactoryResolver, this.injector , selected, this.type, this.data), {});
                this.markerClusterData[i]?.openPopup();
            },
            (error) => {
                this.toastrService.danger(
                    `Details : Données non recupérées`,
                    'Une erreur est survenue.',
                    configNotification
                );
                this.loading = false;
            },
        );
    }

    getEventsDetails(i: number, selected :any){
        this.loading = true;
        this.service.getEventDetails(selected[3], this.date, selected[4]).subscribe(
            (data) => {
                this.data= data;
                this.loading = false;
                this.markerClusterData[i]?.bindPopup(() => createCustomPopup(this.componentFactoryResolver, this.injector , selected, this.type, this.data), {});
                this.markerClusterData[i]?.openPopup();
            },
            (error) => {
                this.toastrService.danger(
                    `Details : Données non recupérées`,
                    'Une erreur est survenue.',
                    configNotification
                );
                this.loading = false;
            },
        );

    }

  getMarker(compteurs: any, type: any) {
    const baseMarker: L.CircleMarker[] = [];
    const popup = new L.Popup({ autoClose: false });

    const elem = document.getElementById("legend");

    for (let i = 0; i < compteurs?.length; i += 1) {
      const latitude = Number(compteurs[i][0]);
      const longitude = Number(compteurs[i][1]);
      popup.setContent(`<div>Numero Compteur : ${compteurs[i][2]}</strong></div>`);

      baseMarker.push(
          L.circleMarker([latitude, longitude], {
            color: compteurs[i][2],
            fillColor: compteurs[i][2],
            fillOpacity: 0.9,
            weight: 1,
            radius: 5,

          }).on('click', () => {
              if (type === "consommation" || type === "tension" || type === "evenements"){
                  elem.hidden = true;
              }
            this.point = compteurs[i];
              if(this.displayLeg ){
                  this.getData(i, this.point);
              }
          }).on('popupclose', () => {
            elem.hidden = false;
          }));
    }
    return baseMarker;
  }


}
