import * as L from "leaflet";

export function  createCustomIcon(color) {
    return L.divIcon({
        className: 'custom-icon',
        html: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="30pt" height="30pt" viewBox="0 0 600.000000 600.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)"
fill="${color}" stroke="none">
<path d="M1970 5665 c0 -16 -6 -25 -15 -25 -10 0 -15 -10 -15 -30 0 -20 5 -30
15 -30 12 0 15 -13 15 -60 0 -59 0 -60 -28 -60 -42 0 -52 -17 -52 -87 l0 -63
110 0 111 0 -3 73 -3 72 -37 3 -38 3 0 60 c0 52 2 59 20 59 15 0 20 7 20 29 0
20 -6 30 -20 34 -12 3 -20 14 -20 26 0 17 -6 21 -30 21 -25 0 -30 -4 -30 -25z"/>
<path d="M2960 5666 c0 -15 -7 -26 -20 -29 -12 -3 -20 -14 -20 -27 0 -13 8
-24 20 -27 17 -5 20 -14 20 -64 l0 -58 -37 -3 -38 -3 -3 -72 -3 -73 111 0 110
0 0 63 c0 70 -10 87 -52 87 -28 0 -28 1 -28 60 0 47 3 60 15 60 10 0 15 10 15
30 0 20 -5 30 -15 30 -9 0 -15 9 -15 25 0 21 -5 25 -30 25 -25 0 -30 -4 -30
-24z"/>
<path d="M3948 5684 c-4 -3 -4 -13 -1 -21 4 -9 -2 -16 -16 -20 -25 -6 -31 -63
-6 -63 18 0 28 -35 23 -81 -3 -30 -7 -35 -38 -39 l-35 -5 -3 -72 -3 -73 106 0
106 0 -3 73 -3 72 -32 3 -33 3 0 60 c0 46 3 59 15 59 10 0 15 10 15 30 0 19
-5 30 -14 30 -8 0 -16 10 -18 23 -2 15 -11 23 -28 25 -14 2 -29 0 -32 -4z"/>
<path d="M1877 5245 c-15 -14 -52 -32 -83 -41 -64 -18 -92 -50 -74 -83 10 -20
18 -21 278 -21 283 0 292 1 292 47 0 25 -30 48 -76 58 -18 3 -53 19 -78 36
-43 27 -54 29 -139 29 -83 -1 -95 -3 -120 -25z"/>
<path d="M2865 5245 c-16 -14 -53 -32 -82 -40 -56 -17 -83 -37 -83 -64 0 -39
15 -41 290 -41 274 0 290 2 290 40 0 25 -31 48 -88 65 -28 9 -64 27 -80 40
-27 23 -38 25 -123 25 -85 -1 -97 -3 -124 -25z"/>
<path d="M3856 5250 c-10 -11 -40 -27 -65 -35 -81 -29 -96 -39 -99 -69 -2 -16
2 -32 10 -37 15 -10 518 -12 542 -3 20 8 21 57 2 73 -7 6 -36 18 -64 26 -28 9
-64 27 -80 40 -28 23 -38 25 -128 24 -84 0 -102 -3 -118 -19z"/>
<path d="M1877 5035 c-15 -14 -52 -32 -83 -41 -64 -18 -92 -50 -74 -83 10 -20
18 -21 278 -21 282 0 292 2 292 46 0 27 -13 37 -75 58 -28 9 -67 27 -87 41
-33 22 -47 25 -130 25 -84 -1 -96 -3 -121 -25z"/>
<path d="M2865 5035 c-16 -14 -53 -32 -82 -40 -56 -17 -83 -37 -83 -64 0 -39
15 -41 290 -41 274 0 290 2 290 40 0 25 -31 48 -88 65 -28 9 -64 27 -80 40
-27 23 -38 25 -123 25 -85 -1 -97 -3 -124 -25z"/>
<path d="M3856 5040 c-10 -11 -40 -27 -65 -35 -81 -29 -96 -39 -99 -69 -2 -16
2 -32 10 -37 15 -10 518 -12 542 -3 20 8 21 57 2 73 -7 6 -36 18 -64 26 -28 9
-64 27 -80 40 -28 23 -38 25 -128 24 -84 0 -102 -3 -118 -19z"/>
<path d="M1883 4829 c-11 -12 -49 -31 -83 -43 -69 -23 -93 -48 -79 -83 l8 -23
269 0 c282 0 292 2 292 46 0 28 -13 37 -80 59 -30 10 -69 29 -86 42 -27 20
-42 23 -125 23 -83 0 -97 -3 -116 -21z"/>
<path d="M2865 4825 c-16 -14 -53 -32 -82 -40 -56 -17 -83 -37 -83 -64 0 -39
15 -41 290 -41 274 0 290 2 290 40 0 25 -31 48 -88 65 -28 9 -64 27 -80 40
-27 23 -38 25 -123 25 -85 -1 -97 -3 -124 -25z"/>
<path d="M3850 4825 c-19 -14 -57 -32 -84 -40 -62 -19 -76 -31 -76 -66 0 -21
6 -30 23 -34 12 -3 139 -4 282 -3 l260 3 3 28 c5 39 -11 53 -73 72 -30 8 -67
26 -83 40 -27 23 -38 25 -124 25 -85 0 -97 -2 -128 -25z"/>
<path d="M1877 4615 c-15 -14 -52 -32 -83 -41 -64 -18 -92 -50 -74 -83 10 -20
18 -21 275 -21 145 0 270 3 279 6 19 7 21 57 4 71 -7 6 -38 18 -68 28 -30 10
-69 29 -86 42 -27 20 -41 23 -125 23 -85 -1 -97 -3 -122 -25z"/>
<path d="M2865 4615 c-16 -14 -53 -32 -82 -40 -56 -17 -83 -37 -83 -64 0 -39
15 -41 290 -41 274 0 290 2 290 40 0 25 -31 48 -88 65 -28 9 -64 27 -80 40
-27 23 -38 25 -123 25 -85 -1 -97 -3 -124 -25z"/>
<path d="M3850 4614 c-19 -13 -57 -31 -84 -39 -62 -19 -76 -31 -76 -66 0 -21
6 -30 23 -34 12 -3 139 -4 282 -3 l260 3 3 28 c5 39 -11 53 -73 72 -30 8 -67
26 -83 40 -27 23 -38 25 -123 25 -84 -1 -98 -4 -129 -26z"/>
<path d="M1877 4405 c-15 -14 -52 -32 -83 -41 -64 -18 -92 -50 -74 -83 10 -20
18 -21 278 -21 282 0 292 2 292 46 0 28 -13 37 -80 59 -30 10 -69 29 -86 42
-27 20 -41 23 -125 23 -85 -1 -97 -3 -122 -25z"/>
<path d="M2865 4404 c-16 -13 -54 -31 -82 -40 -58 -17 -83 -36 -83 -63 0 -39
15 -41 290 -41 274 0 290 2 290 40 0 25 -31 48 -88 65 -28 9 -64 27 -80 40
-27 23 -38 25 -123 25 -85 -1 -97 -3 -124 -26z"/>
<path d="M3850 4405 c-19 -14 -57 -32 -85 -41 -27 -9 -56 -21 -62 -27 -18 -14
-16 -64 3 -71 20 -8 518 -8 538 0 20 8 21 57 2 73 -7 6 -36 18 -64 26 -28 9
-64 27 -80 40 -27 23 -38 25 -123 25 -83 -1 -98 -4 -129 -25z"/>
<path d="M1877 4195 c-15 -14 -52 -32 -83 -41 -64 -18 -92 -50 -74 -83 10 -20
18 -21 278 -21 282 0 292 2 292 46 0 28 -13 38 -80 59 -30 10 -69 28 -87 41
-28 21 -42 24 -125 24 -84 -1 -96 -3 -121 -25z"/>
<path d="M2867 4195 c-15 -13 -51 -31 -79 -40 -57 -17 -88 -40 -88 -65 0 -38
16 -40 290 -40 274 0 290 2 290 40 0 25 -31 48 -88 65 -28 9 -64 27 -80 40
-27 23 -38 25 -123 25 -85 -1 -97 -3 -122 -25z"/>
<path d="M3850 4195 c-19 -14 -57 -32 -85 -41 -65 -21 -75 -30 -75 -65 0 -21
6 -30 23 -34 12 -3 139 -4 282 -3 l260 3 3 28 c5 39 -11 53 -73 72 -30 8 -67
26 -83 40 -27 23 -38 25 -123 25 -83 -1 -98 -4 -129 -25z"/>
<path d="M1890 3925 l0 -85 110 0 110 0 0 85 0 85 -110 0 -110 0 0 -85z"/>
<path d="M2880 3925 l0 -85 110 0 110 0 0 85 0 85 -110 0 -110 0 0 -85z"/>
<path d="M3870 3925 l0 -85 105 0 105 0 0 85 0 85 -105 0 -105 0 0 -85z"/>
<path d="M1297 3762 c-14 -15 -17 -40 -17 -120 l0 -102 1710 0 1710 0 0 103
c0 88 -3 106 -18 120 -17 16 -149 17 -1694 17 -1610 0 -1676 -1 -1691 -18z"/>
<path d="M694 3475 l-24 -24 2 -1191 c3 -1166 3 -1192 22 -1206 16 -11 70 -14
273 -14 l253 0 0 1230 0 1230 -250 0 -251 0 -25 -25z"/>
<path d="M1280 2270 l0 -1231 78 3 77 3 0 1225 0 1225 -77 3 -78 3 0 -1231z"/>
<path d="M1510 2270 l0 -1230 80 0 80 0 -2 1228 -3 1227 -77 3 -78 3 0 -1231z"/>
<path d="M1747 3493 c-4 -3 -7 -557 -7 -1230 l0 -1223 80 0 80 0 0 1230 0
1230 -73 0 c-41 0 -77 -3 -80 -7z"/>
<path d="M1980 2270 l0 -1231 78 3 77 3 0 1225 0 1225 -77 3 -78 3 0 -1231z"/>
<path d="M2210 2270 l0 -1230 80 0 80 0 -2 1228 -3 1227 -77 3 -78 3 0 -1231z"/>
<path d="M2447 3493 c-4 -3 -7 -557 -7 -1230 l0 -1223 80 0 80 0 0 1230 0
1230 -73 0 c-41 0 -77 -3 -80 -7z"/>
<path d="M2680 2270 l0 -1231 78 3 77 3 0 1225 0 1225 -77 3 -78 3 0 -1231z"/>
<path d="M2910 2270 l0 -1230 80 0 80 0 0 1230 0 1230 -80 0 -80 0 0 -1230z"/>
<path d="M3147 3493 c-4 -3 -7 -557 -7 -1230 l0 -1223 80 0 80 0 0 1230 0
1230 -73 0 c-41 0 -77 -3 -80 -7z"/>
<path d="M3380 2270 l0 -1230 80 0 80 0 -2 1228 -3 1227 -77 3 -78 3 0 -1231z"/>
<path d="M3610 2270 l0 -1230 80 0 80 0 0 1230 0 1230 -80 0 -80 0 0 -1230z"/>
<path d="M3847 3493 c-4 -3 -7 -557 -7 -1230 l0 -1223 80 0 80 0 0 1230 0
1230 -73 0 c-41 0 -77 -3 -80 -7z"/>
<path d="M4080 2270 l0 -1231 78 3 77 3 0 1225 0 1225 -77 3 -78 3 0 -1231z"/>
<path d="M4310 2270 l0 -1230 80 0 80 0 0 1230 0 1230 -80 0 -80 0 0 -1230z"/>
<path d="M4547 3493 c-4 -3 -7 -557 -7 -1230 l0 -1223 80 0 80 0 0 1230 0
1230 -73 0 c-41 0 -77 -3 -80 -7z"/>
<path d="M4760 2270 l0 -1230 254 0 c218 0 257 2 270 16 9 8 16 23 16 32 0 9
0 548 0 1197 l0 1180 -21 18 c-19 15 -48 17 -270 17 l-249 0 0 -1230z"/>
<path d="M1280 890 c0 -98 2 -110 19 -120 13 -7 559 -10 1695 -10 1509 0 1676
2 1690 16 13 12 16 38 16 120 l0 104 -1710 0 -1710 0 0 -110z"/>
<path d="M1634 697 c-2 -7 -3 -82 -2 -167 l3 -155 35 0 35 0 5 113 c4 85 9
115 20 122 8 5 94 10 190 10 96 0 182 -5 190 -10 11 -7 16 -37 20 -122 l5
-113 35 0 35 0 0 165 0 165 -283 3 c-225 2 -284 0 -288 -11z"/>
<path d="M3774 697 c-2 -7 -3 -82 -2 -167 l3 -155 35 0 35 0 3 110 c2 89 6
112 19 123 26 19 367 17 386 -3 11 -10 15 -42 16 -117 1 -57 6 -107 10 -111 5
-5 21 -7 37 -5 l29 3 0 165 0 165 -283 3 c-225 2 -284 0 -288 -11z"/>
</g>
</svg>`

    });
}
