import {Component, ComponentFactoryResolver, Injector, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {NbToastrService} from "@nebular/theme";
import {Store} from "@ngrx/store";
import * as L from "leaflet";
import {createCustomPopup} from "../../../../../utils/marker";
import {configNotification} from "../../../../../utils/variables";

@Component({
  selector: 'app-carte-coupures',
  templateUrl: './carte-coupures.component.html',
  styleUrls: ['./carte-coupures.component.css']
})
export class CarteCoupuresComponent{


  tempSubscription!: Subscription;
  date;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector, private service: MainScreenService,
              private toastrService: NbToastrService,
              private store: Store<any>) {
    this.tempSubscription = this.store
        ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
  }

  icons: any;

  channel: any;

  _compteurs: any;

  _allMeters;

  dataOff: any;

  threeOff: any;

  iconThree;

  defaultIcon: any;

  type;

  point;

  @Input() set allMeters(value) {
    this._allMeters = value.dataOff;
  }

  @Input() titre = '';

  @Input() dateOfData = '';

  data;

  map: L.Map;

  layerGroup: L.LayerGroup;

  baseMarker: any = [];

  baseMarker2: any = [];

  markerClusterData: any = [];

  markerClusterData2: any = [];

  @Input() set compteurs(value : any){
    this.layerGroup?.clearLayers();
    this.initializeMap();
    if(value){
      this._compteurs = value.compteurs.coordonnees;
      this.threeOff = value.threeOff;
      this.dataOff = value.dataOff;
      this.legend = value.compteurs.legend;
      this.type = value.type;
      this.displayLeg = value.displayLegend;
      this.addMarkers();
    }
  }

  get compteurs(){
    return this._compteurs;
  }

  preSetDate(state: any) {
    if(state.period !== 'intervalle'){
      this.date =   this.date = state.selected_date;
    } else {
      this.date =  state.end_date;
    }
  }

  get allMeters(){
    return this._allMeters;
  }

  loading = false;

  legend: any;

  displayLeg;



  /* Filtre la carte au clique sur la légende */
  refresh($event){
    this.layerGroup.clearLayers();
    if($event.length === 0)
    {
      this.compteurs = {compteurs: { coordonnees: this.allMeters, legend: this.legend}, dataOff: this.allMeters, threeOff: this.threeOff, type: this.type, displayLegend: this.displayLeg};
    }else {
      let meters = this.allMeters;
      let filteredMeters = [];
      let listMeters = [];
      $event.forEach((el) => {
        filteredMeters = meters.filter((element) => element[2] === el.couleur);
        listMeters =  listMeters.concat(filteredMeters);
      });
      this.compteurs = {compteurs: { coordonnees: listMeters, legend: this.legend}, dataOff: listMeters, threeOff: this.threeOff, type: this.type, displayLegend: this.displayLeg};
    }
  }

  /* Initialise la carte coupure */
  initializeMap() {
    if (this.map != undefined) { this.map.remove(); }
    this.map = L.map('map').setView([18.1, -15.95], 12, );
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxNativeZoom: 18,
      maxZoom: 18,
      minZoom: 10,
      attribution: '<a href="https://www.akilee-by-ines.com">Akilee</a>',
    }).addTo(this.map);
    this.map.removeControl(this.map.zoomControl);
    this.layerGroup = L.layerGroup().addTo(this.map);
  }

  addMarkers() {
    this.getCoupureMarker(this.threeOff, this.type);
    this.getMarker(this.dataOff, this.type);
  }

  getDetailMarker(i: number, selected :any) {
    this.loading = true;
    this.service.getDetailsCoupure(selected[3], this.date).subscribe(
        (data) => {
          this.data= data;
          this.loading = false;
          this.markerClusterData2[i].bindPopup(() => createCustomPopup(this.componentFactoryResolver, this.injector , selected, this.type, this.data), {});
          this.markerClusterData2[i].openPopup();
        },
        (error) => {
          this.toastrService.danger(
              `Details : Données non recupérées`,
              'Une erreur est survenue.',
              configNotification
          );
          this.loading = false;
        },
    );
  }

  getDetailCercle(i: number, selected :any) {
    this.loading = true;
    this.service.getDetailsCoupure(selected[3], this.date).subscribe(
        (data) => {
          this.data= data;
          this.loading = false;
          this.markerClusterData[i].bindPopup(() => createCustomPopup(this.componentFactoryResolver, this.injector , selected, this.type, this.data), {});
          this.markerClusterData[i].openPopup();
        },
        (error) => {
          this.toastrService.danger(
              `Details : Données non recupérées`,
              'Une erreur est survenue.',
              configNotification
          );
          this.loading = false;
        },
    );
  }

  getCoupureMarker(compteurs: any, type: any) {
    this.baseMarker = [];
    this.markerClusterData2 = [];
    const elem = document.getElementById("legend");
    this.iconThree = L.icon({
      iconUrl: 'assets/icons/threeOff.png',
      iconSize: [20, 20],
    });

    for (let i = 0; i < compteurs.length; i += 1) {
      const latitude = Number(compteurs[i][0]);
      const longitude = Number(compteurs[i][1]);
      if (latitude && longitude) {
        this.baseMarker = L.marker([latitude, longitude], {
          icon: this.iconThree,
        }) .on('click', () => {
          if (type === "coupure") {
            elem.hidden = true;
          }
          this.point = compteurs[i];
          if (this.displayLeg) {
            this.getDetailMarker(i, this.point);
          }
        }).on('popupclose', () => {
          elem.hidden = false;
        });
        this.markerClusterData2.push(
            this.baseMarker
        );
        this.layerGroup.addLayer(this.baseMarker);
      }
    }
  }

  getMarker(compteurs: any, type: any) {
    this.baseMarker2 = [];
    this.markerClusterData = [];
    const elem = document.getElementById("legend");
    for (let i = 0; i < compteurs?.length; i += 1) {
      const latitude = Number(compteurs[i][0]);
      const longitude = Number(compteurs[i][1]);
      if (latitude && longitude) {
        this.baseMarker2 = L.circleMarker([latitude, longitude], {
          color: compteurs[i][2],
          fillColor: compteurs[i][2],
          fillOpacity: 0.9,
          weight: 1,
          radius: 5,
        }).on('click', () => {
          if (type === "coupure") {
            elem.hidden = true;
          }
          this.point = compteurs[i];
          if (this.displayLeg) {
            this.getDetailCercle(i, this.point);
          }
        }).on('popupclose', () => {
          elem.hidden = false;
        });
        this.markerClusterData.push(
            this.baseMarker2
        );
        this.layerGroup.addLayer(this.baseMarker2);
      }
    }
  }

}
