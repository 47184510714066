<div>
    <div class="recovery-stats">
        <div class="stats-row">
            <p class="stats-label">Clients à recouvrer</p>
            <p class="stats-value">{{ clients }}</p>
        </div>
        <div class="stats-row">
            <p class="stats-label">Montant recouvré</p>
            <p class="stats-value">{{recouvrementTotal}}</p>
        </div>
    </div>
    <highcharts-chart *ngIf="data"
        [Highcharts]="Highcharts"
        [options]="chartOptions"
        [(update)]="chartUpdateFlag"
        class="graphe">
    </highcharts-chart>
</div>
