import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import {HISTO_PAIR} from "../../../../../utils/charts/area-chart-option";
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-histogramme-pair',
  templateUrl: './histogramme-pair.component.html',
  styleUrls: ['./histogramme-pair.component.css']
})
export class HistogrammePairComponent {

  highcharts = Highcharts;
  chartOptions =  _.cloneDeep(HISTO_PAIR);
  _data;
  chartUpdateFlag = false;

  @Input() set data(value){
    this.chartOptions.series[0].data = [];
    this.chartOptions.series[1].data = [];
    this.chartOptions.xAxis.categories = [];
    this._data = value;
    const diffMonths = this.calculateMonths(this._data.data);

  if(this._data.data.length > 0){
    this._data.data.forEach((element) => {
      if (this._data.type == 'venteParAgence')
      {
        this.chartOptions.colors = ['rgba(189, 87, 65, 1)', '#01ABAE'];
        this.chartOptions.xAxis.categories.push(element[0]);
        this.chartOptions.series[0].data.push(Number(element[2]));
        this.chartOptions.series[1].data.push(Number(element[3]));
        this.chartOptions.series[0].borderColor = 'rgba(189, 87, 65, 1)';
        this.chartOptions.series[1].borderColor = '#01ABAE';

      }else if(this._data.type == 'venteParMois')
      {
        this.chartOptions.colors = ['rgba(189, 87, 65, 1)', '#01ABAE'];
        this.chartOptions.legend.itemStyle.color = 'white';


        if(diffMonths > 3) {
          this.chartOptions.xAxis.categories.push(moment(element[0]).format('MM-yyyy'));
        }else{
          this.chartOptions.xAxis.categories.push(moment(element[0]).format('DD-MM'));
        }

        this.chartOptions.series[0].data.push({
          y: Number(element[1])
        });
        this.chartOptions.series[1].data.push({
          y: Number(element[2])
        });
        this.chartOptions.series[0].borderColor = 'rgba(189, 87, 65, 1)';
        this.chartOptions.series[1].borderColor = '#01ABAE';
      }
    });
  }
    this.chartUpdateFlag = true;
  }

  get data() {
    return this._data;
  }

  extractYearMonth(date: string): string {
    const [year, month] = date.split('-');
    return `${month}-${year}`;
  }

  calculateMonths(data) {
    if(data.length > 0){
      const startDate = new Date(data[0][0]);
      const endDate = new Date(data[data.length - 1][0]);
      const diffMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
      return diffMonths;
    }
    return -1;
  }
}
