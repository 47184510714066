import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import * as _ from 'lodash';
import {QUALIMETRIE_DATA} from "../../../../../utils/charts/area-chart-option";

@Component({
  selector: 'app-courbe-qualimetrie',
  templateUrl: './courbe-qualimetrie.component.html',
  styleUrls: ['./courbe-qualimetrie.component.scss']
})
export class CourbeQualimetrieComponent {

  protected readonly Highcharts = Highcharts;
  dataOption = _.cloneDeep(QUALIMETRIE_DATA);
  chartUpdateFlag: any;
  _data;

  @Input() set data(value){
    if(value){
      this._data = value;
      this.dataOption.series[0].data = this._data.phase_1;
      this.dataOption.series[1].data = this._data.phase_2;
      this.dataOption.series[2].data = this._data.phase_3;
      this.dataOption.xAxis.categories = this._data.mois;
      this.dataOption.yAxis.plotLines[0].value = this._data.ref_min;
      this.dataOption.yAxis.plotLines[1].value = this._data.ref;
      this.dataOption.yAxis.plotLines[2].value = this._data.ref_max;
      this.dataOption.yAxis.plotLines[3].value = this._data.ref_min;
      this.dataOption.yAxis.plotLines[3].label.text = this._data.ref_min + 'V';
      this.dataOption.yAxis.plotLines[4].value = this._data.ref_max;
      this.dataOption.yAxis.plotLines[4].label.text = this._data.ref_max + 'V';
      this.dataOption.xAxis.categories = this._data.jours;
      this.chartUpdateFlag = true;
    }
  }

  get data(){
    return this._data;
  }

}
