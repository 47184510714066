<div class="repartition">
    <div class="client">
    <span class="title-text">
        Nombre Clients: {{nombreClient}}
    </span>
        <img src="assets/icons/customers.png"
             alt="power13282"
        />
    </div>
    <div class="client">
    <span class="title-text">
        Client prépayé: {{prepaid}}
    </span>
        <img src="assets/icons/customers.png"
             alt="power13282"
        />
    </div>
    <div class="client">
    <span class="title-text">
        Client postpayé: {{postpaid}}
    </span>
        <img src="assets/icons/customers.png"
             alt="power13282"
        />
    </div>
</div>
<table class="table table-bordered table-dark">
    <tr class="left">
        <th scope="col">Dettes</th>
        <th class="no-data" >Pas de données</th>
        <th class="no-data">Pas de données</th>
    </tr>
    <tbody>
    <tr >
        <td class="no-data"></td>
        <td>Solde global dette</td>
        <td>{{montant}}</td>
    </tr>
    <tr >
        <td class="no-data"></td>
        <td>Montant Recouvré</td>
        <td>{{montantRecouvre}}</td>
    </tr>
    <tr class="left">
        <th scope="col">Avoir</th>
        <th class="no-data" >Pas de données</th>
        <th class="no-data">Pas de données</th>
    </tr>
    <tr >
        <td class="no-data"></td>
        <td>Montant Remboursé</td>
        <td>{{montantRembourse}}</td>
    </tr>
    </tbody>
</table>

