export const PIE_CHART_OPTIONS = {
    chart: {
        type: 'pie',
        backgroundColor:'rgba(255, 255, 255, 0.0)',
        marginRight: 0

    },
    legend: {
        enabled: true,
        floating: true,
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        itemStyle:{color: '#fff'},
        navigation: {
            activeColor: '#B3B6B6',
            animation: true,
            inactiveColor: '#CCC',
            style: {
                fontWeight: 'bold',
                color: '#fff'
            }
        }
    },
    exporting: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    title: {
        text: '',
        verticalAlign: 'middle',
        x: 0,
        style: {
            fontSize: '0.4vw',
            color: '#fff',
        }
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            innerSize: '65%',
            borderWidth: 0,
            showInLegend: true,
            colors: [],
            dataLabels: {
                enabled: false,
                shadow: false,
                connectorWidth: 1,
                distance: '1%',
                alignTo: 'connectors',
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                style: {
                    color: 'white',
                    textShadow: false,
                     fontSize: '0.6em',
                }
            },
        }
    },
    series: [{
        name: '',
        data: []
    }
    ],
};
