import { Component } from '@angular/core';

@Component({
  selector: 'app-surtension-baisse-tension',
  templateUrl: './surtension-baisse-tension.component.html',
  styleUrls: ['./surtension-baisse-tension.component.css']
})
export class SurtensionBaisseTensionComponent {

}
