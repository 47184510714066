<ngx-loading
        [show]="loading"
></ngx-loading>
<app-carte-coupures
        *ngIf="compteurs && dateCoupure"
        [compteurs]="compteurs"
        [allMeters]="compteurs"
        [dateOfData]="dateCoupure"
        [titre]="' - Continuité de service'"
></app-carte-coupures>
