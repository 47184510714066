<nb-layout>
    <nb-layout-column>
    <header>
        <div class="header-container">
            <div class="logo">
                <img alt="Logo Somelec" class="somelec" src="assets/playground_assets/logosomelec213288-4gf-300h.png">
            </div>
            <div>
                <app-selecter></app-selecter>
            </div>
            <nav>
                <ul>
                    <li>
                        <a routerLink="geographique" routerLinkActive="active" class="menu-item-text">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                 width="2vw" height="2vh" viewBox="0 0 512.000000 512.000000"
                                 preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                   fill="#ffffff" stroke="none">
                                    <path d="M2347 5105 c-694 -113 -1136 -671 -1138 -1433 0 -160 10 -246 46
                                    -392 54 -215 137 -421 280 -698 47 -89 85 -165 85 -167 0 -3 -133 -5 -295 -5
                                    -316 0 -338 -3 -381 -51 -31 -35 -944 -2170 -944 -2209 0 -50 35 -107 80 -130
                                    39 -20 53 -20 2480 -20 2427 0 2441 0 2480 20 45 23 80 80 80 130 0 39 -913
                                    2174 -944 2209 -43 48 -65 51 -381 51 -162 0 -295 2 -295 4 0 3 40 83 89 178
                                    230 443 322 750 322 1068 -1 347 -82 634 -254 890 -64 96 -241 273 -337 337
                                    -144 96 -309 166 -485 204 -122 27 -368 34 -488 14z m363 -300 c168 -25 293
                                    -72 426 -161 307 -203 474 -551 474 -986 0 -375 -190 -834 -600 -1452 -111
                                    -166 -348 -492 -424 -581 l-26 -30 -26 30 c-72 85 -312 413 -424 581 -296 445
                                    -483 824 -566 1149 -27 103 -29 123 -29 310 0 173 3 214 23 305 29 128 79 259
                                    141 364 54 92 179 229 265 291 219 155 499 221 766 180z m-840 -2786 c34 -50
                                    90 -131 126 -181 35 -50 63 -92 61 -93 -1 -2 -299 -154 -662 -340 -363 -186
                                    -668 -342 -678 -347 -15 -8 -14 -2 6 44 13 29 115 268 228 531 l204 477 327 0
                                    327 0 61 -91z m2318 -430 c123 -286 222 -522 220 -523 -2 -2 -334 -175 -738
                                    -385 l-735 -381 -155 0 -155 0 -462 463 -462 462 265 135 c146 74 270 135 274
                                    135 5 0 55 -56 112 -125 120 -144 121 -145 170 -158 61 -17 111 2 164 62 123
                                    136 365 455 528 694 l97 142 327 0 327 0 223 -521z m-2447 -1287 c-247 -1
                                    -654 -1 -905 0 l-455 3 66 155 67 156 451 231 450 231 388 -386 387 -387 -449
                                    -3z m2897 238 l101 -235 -285 -3 c-156 -1 -413 -1 -570 0 l-286 3 464 241
                                    c254 133 466 239 469 235 4 -3 52 -112 107 -241z"/>
                                    <path d="M2487 4209 c-130 -19 -266 -117 -324 -235 -43 -84 -57 -153 -50 -246
                                    14 -203 169 -372 377 -409 251 -45 500 151 517 409 21 297 -225 524 -520 481z
                                    m154 -322 c67 -44 88 -135 47 -197 -37 -54 -72 -74 -128 -74 -56 0 -91 20
                                    -127 74 -63 91 12 220 127 220 32 0 58 -7 81 -23z"/>
                                </g>
                            </svg>
                            Vue Carte
                        </a>
                    </li>
                    <li>
                        <a routerLink="graphique"  routerLinkActive="active" class="menu-item-text">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                 width="2vw" height="2vh" viewBox="0 0 512.000000 512.000000"
                                 preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                   fill="#ffffff" stroke="none">
                                    <path d="M3884 5086 c-24 -24 -34 -43 -34 -66 0 -23 10 -41 34 -66 l34 -34
                                203 0 204 0 -495 -495 -495 -495 -200 200 c-201 200 -201 200 -245 200 l-45 0
                                -921 -922 c-640 -640 -923 -931 -926 -948 -10 -69 30 -120 96 -120 l41 0 877
                                877 878 878 197 -197 c198 -196 226 -217 275 -203 13 4 265 248 561 543 l537
                                537 1 -165 c0 -193 8 -227 60 -254 42 -23 79 -17 115 19 l24 24 0 326 c0 205
                                -4 333 -10 346 -25 46 -48 49 -401 49 l-331 0 -34 -34z"/>
                                    <path d="M3800 3629 c-60 -27 -107 -74 -136 -134 l-24 -50 0 -1622 0 -1623
                                -280 0 -280 0 0 888 c0 573 -4 900 -10 924 -18 62 -71 128 -130 162 l-55 31
                                -320 0 c-309 0 -321 -1 -361 -22 -54 -29 -102 -79 -128 -133 -21 -45 -21 -51
                                -24 -947 l-3 -903 -274 0 -274 0 -3 478 c-3 473 -3 477 -26 527 -32 69 -86
                                121 -149 145 -49 18 -79 20 -342 20 -317 0 -345 -4 -414 -61 -20 -17 -49 -52
                                -65 -77 l-27 -47 -3 -492 -3 -493 -125 0 c-164 0 -204 -19 -204 -100 0 -42 15
                                -67 50 -85 26 -13 302 -15 2370 -15 2068 0 2344 2 2370 15 35 18 50 43 50 85
                                0 80 -40 100 -200 100 l-120 0 0 1623 0 1622 -24 50 c-29 63 -68 102 -131 131
                                -49 23 -56 24 -355 24 -285 0 -308 -2 -350 -21z m642 -196 c17 -15 18 -79 18
                                -1625 l0 -1608 -310 0 -310 0 0 1607 c0 1481 1 1608 17 1625 15 17 37 18 291
                                18 242 0 278 -2 294 -17z m-1585 -1445 l23 -21 0 -884 0 -883 -315 0 -315 0 0
                                880 0 881 25 24 24 25 267 0 c261 0 268 -1 291 -22z m-1589 -834 l22 -15 0
                                -470 0 -469 -310 0 -310 0 0 465 c0 452 1 466 20 485 19 19 33 20 288 20 226
                                0 271 -2 290 -16z"/>
                                    <path d="M733 2183 c-12 -2 -33 -16 -47 -30 -58 -58 -14 -163 68 -163 105 0
                                137 146 41 186 -19 8 -36 13 -37 13 -2 -1 -13 -4 -25 -6z"/>
                                </g>
                            </svg>
                            Vue Graphique
                        </a>
                    </li>
                </ul>
            </nav>
            <app-date [clickable]="clickable" *ngIf="getName() !== 'Parc de clients'"></app-date>
            <div class="menu-title">{{getName()}}</div>
            <div class="menu" (click)="displayRightMenu()">
                <img alt="Menu" class="ico" src="assets/icons/menu.svg">
            </div>
        </div>
       <div class="submenu" *ngIf="dropMenu">
               <ul>
                   <li> <a routerLink="{{baseUrl()}}/consommation" routerLinkActive="active"  class="menu-item-text" (click)="displayRightMenu()">
                       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="2vw" height="2vh" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                           <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              fill="#ffffff" stroke="none">
                               <path d="M0 2560 l0 -2560 2560 0 2560 0 0 2560 0 2560 -2560 0 -2560 0 0
                                -2560z m2940 2262 c691 -124 1257 -519 1606 -1119 277 -476 371 -1059 259
                                -1608 -140 -689 -610 -1291 -1250 -1600 -301 -145 -579 -213 -922 -222 -356
                                -10 -663 46 -970 177 -726 311 -1235 962 -1368 1750 -22 130 -31 448 -16 591
                                55 529 287 1017 661 1389 364 363 819 586 1330 655 156 21 519 14 670 -13z"/>
                                                               <path d="M2839 4101 c-14 -5 -35 -13 -46 -19 -19 -11 -1204 -1616 -1233 -1671
                                -18 -35 -8 -121 18 -158 43 -60 59 -63 386 -63 163 0 296 -2 296 -5 0 -4 -41
                                -228 -90 -500 -49 -272 -90 -507 -90 -522 0 -41 40 -104 79 -127 45 -26 117
                                -26 161 0 24 14 186 242 631 889 329 478 603 880 609 893 29 64 2 148 -63 196
                                -27 20 -42 21 -334 24 -233 2 -304 6 -301 15 3 7 45 213 93 458 l89 446 -18
                                47 c-28 73 -116 119 -187 97z"/>

                           </g>
                       </svg>
                       Consommation
                   </a></li>
                   <li *ngIf="baseUrl() === 'geographique'"  (click)="displayRightMenu()">
                       <a routerLink="{{baseUrl()}}/tensions" routerLinkActive="active" class="menu-item-text">
                           <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="2vw" height="2vh" viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet">

                               <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                  fill="#ffffff" stroke="none">
                                   <path d="M620 5105 c-160 -33 -267 -92 -391 -215 -82 -81 -102 -108 -142 -190
                                -90 -183 -82 47 -85 -2260 -2 -1541 0 -2059 9 -2100 34 -160 169 -296 327
                                -329 75 -16 4369 -16 4444 0 160 34 297 173 328 334 8 44 10 604 8 2100 -3
                                2301 5 2072 -85 2255 -39 81 -60 109 -142 191 -82 82 -110 103 -191 142 -183
                                90 28 82 -2115 84 -1551 1 -1912 -1 -1965 -12z m3855 -170 c226 -48 413 -236
                                460 -462 13 -63 15 -332 13 -2095 -3 -1951 -4 -2024 -22 -2058 -26 -49 -74
                                -98 -121 -123 l-40 -22 -2205 0 c-2131 0 -2206 1 -2240 19 -49 26 -98 74 -123
                                121 l-22 40 -3 2023 c-2 2244 -7 2097 64 2242 73 150 239 279 404 314 100 22
                                3733 22 3835 1z"/>
                                                                   <path d="M2335 4774 c-515 -38 -872 -104 -1275 -237 -274 -91 -657 -257 -697
                                -303 l-23 -25 0 -1215 c0 -1293 -1 -1260 45 -1278 22 -8 4328 -8 4350 0 46 18
                                45 -15 45 1277 0 1192 0 1215 -20 1239 -21 27 -219 122 -435 208 -411 165
                                -876 276 -1340 320 -114 11 -562 21 -650 14z m485 -174 c598 -37 1167 -180
                                1673 -418 l117 -55 0 -1123 0 -1124 -2050 0 -2050 0 0 1123 0 1124 108 51
                                c466 221 1027 369 1552 412 270 21 423 24 650 10z"/>
                                                                   <path d="M2380 4433 c-502 -26 -967 -133 -1415 -324 -183 -78 -257 -118 -272
                                -149 -10 -19 -13 -227 -13 -937 l0 -911 26 -31 26 -31 1828 0 1828 0 26 31 26
                                31 0 911 c0 710 -3 918 -13 937 -32 65 -488 251 -842 345 -381 100 -832 148
                                -1205 128z m390 -173 c506 -31 972 -147 1423 -354 l77 -35 0 -826 0 -825 -516
                                2 -516 3 -19 73 c-22 87 -48 148 -90 209 l-31 46 181 181 181 181 43 -43 c53
                                -52 99 -57 138 -16 22 24 24 34 27 152 4 144 -8 199 -48 219 -18 10 -66 13
                                -172 11 -137 -3 -149 -5 -170 -25 -37 -37 -30 -85 20 -136 l42 -42 -183 -182
                                -182 -182 -25 21 c-38 31 -157 85 -230 104 -85 22 -236 22 -320 1 -36 -10
                                -103 -36 -150 -58 -69 -34 -101 -58 -170 -128 -98 -98 -151 -192 -180 -313
                                l-17 -73 -516 -3 -517 -2 0 825 0 824 77 37 c423 198 928 325 1400 353 190 12
                                261 12 443 1z m-70 -1637 c88 -24 151 -62 221 -132 68 -68 121 -160 134 -233
                                l7 -38 -502 0 -502 0 7 38 c11 60 62 156 112 213 135 149 328 206 523 152z"/>
                                                                   <path d="M2523 4085 c-39 -17 -53 -59 -53 -167 0 -43 -2 -78 -5 -78 -2 0 -32
                                18 -66 40 -34 22 -69 41 -78 41 -78 6 -128 -67 -87 -127 26 -37 294 -207 326
                                -207 32 0 300 170 326 207 20 29 17 69 -7 100 -31 40 -79 35 -157 -14 -34 -22
                                -65 -40 -67 -40 -3 0 -5 35 -5 78 0 110 -13 150 -55 168 -40 16 -32 17 -72 -1z"/>
                                                                   <path d="M1735 3985 c-52 -51 -16 -145 54 -145 46 0 85 39 85 85 0 74 -88 112
                                -139 60z"/>
                                                                   <path d="M3269 3981 c-17 -18 -29 -40 -29 -56 0 -38 50 -85 90 -85 44 0 80 39
                                80 85 0 79 -84 112 -141 56z"/>
                                                                   <path d="M1395 3817 c-57 -48 -20 -147 55 -147 16 0 41 10 55 23 57 48 20 147
                                -55 147 -16 0 -41 -10 -55 -23z"/>
                                                                   <path d="M3614 3816 c-58 -49 -20 -146 57 -146 43 0 79 39 79 85 0 74 -80 110
                                -136 61z"/>
                                                                   <path d="M1075 3659 c-32 -19 -45 -41 -45 -79 0 -33 10 -46 109 -143 92 -91
                                113 -107 140 -107 45 0 81 38 81 86 0 34 -10 47 -102 141 -107 107 -141 126
                                -183 102z"/>
                                                                   <path d="M3975 3660 c-31 -13 -215 -205 -216 -226 -4 -62 30 -104 83 -104 26
                                0 49 17 139 107 94 92 109 111 109 139 0 46 -9 63 -41 79 -31 17 -45 18 -74 5z"/>
                                                                   <path d="M620 1529 c-111 -22 -199 -92 -248 -198 -37 -80 -37 -192 0 -272 51
                                -111 137 -177 258 -200 143 -26 284 47 358 186 24 45 27 61 27 150 0 89 -3
                                105 -27 150 -71 134 -227 212 -368 184z m159 -197 c46 -34 71 -81 71 -134 0
                                -184 -246 -240 -321 -74 -23 52 -23 90 0 142 43 93 168 127 250 66z"/>
                                                                   <path d="M2014 1526 c-18 -8 -36 -27 -44 -46 -13 -32 -11 -38 257 -575 247
                                -494 274 -543 303 -555 27 -11 36 -11 63 2 29 14 61 73 300 553 229 457 267
                                541 262 566 -13 58 -94 82 -138 41 -12 -11 -119 -215 -237 -452 -118 -236
                                -217 -430 -220 -430 -3 0 -102 194 -220 430 -118 237 -224 439 -235 449 -29
                                27 -56 32 -91 17z"/>
                                                                   <path d="M4290 1529 c-111 -22 -199 -92 -249 -198 -21 -46 -26 -70 -26 -136 0
                                -71 4 -89 33 -147 59 -121 168 -191 296 -192 107 0 167 24 242 98 74 74 104
                                142 104 241 0 210 -196 374 -400 334z m140 -184 c57 -29 90 -87 90 -156 0 -49
                                -19 -88 -60 -123 -148 -124 -351 55 -250 221 44 73 140 98 220 58z"/>
                                                                   <path d="M1223 828 c-24 -27 -30 -52 -20 -90 15 -56 32 -58 419 -58 381 0 392
                                2 416 54 17 33 9 69 -20 96 -21 19 -34 20 -398 20 l-377 0 -20 -22z"/>
                                                                   <path d="M3232 835 c-83 -36 -144 -116 -156 -205 -8 -54 8 -89 48 -108 51 -25
                                116 14 116 69 0 42 45 89 85 89 53 0 75 -26 91 -109 16 -86 46 -138 105 -181
                                125 -90 301 -48 372 90 39 77 40 145 3 177 -15 13 -40 23 -55 23 -30 0 -81
                                -34 -81 -53 0 -72 -53 -125 -111 -112 -38 9 -57 39 -68 106 -13 74 -24 100
                                -63 144 -65 75 -199 108 -286 70z"/>
                                                                   <path d="M1226 488 c-38 -35 -39 -86 -1 -123 51 -52 136 -15 138 59 2 73 -83
                                112 -137 64z"/>
                                                                   <path d="M1565 485 c-50 -49 -24 -130 46 -141 28 -5 41 -1 62 17 41 36 45 78
                                12 117 -34 41 -84 44 -120 7z"/>
                                                                   <path d="M1905 485 c-48 -47 -25 -125 42 -141 53 -14 113 46 99 99 -16 66 -95
                                89 -141 42z"/>
                               </g>
                           </svg>
                           {{nameQuality}}
                       </a>
                   </li>
                   <li (click)="displayRightMenu()" *ngIf="baseUrl() === 'geographique'">
                       <a routerLink="{{baseUrl()}}/coupure" routerLinkActive="active"  class="menu-item-text">
                           <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="2vw" height="2vh" viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet">

                               <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                  fill="#ffffff" stroke="none">
                                   <path d="M620 5105 c-160 -33 -267 -92 -391 -215 -82 -81 -102 -108 -142 -190
                                -90 -183 -82 47 -85 -2260 -2 -1541 0 -2059 9 -2100 34 -160 169 -296 327
                                -329 75 -16 4369 -16 4444 0 160 34 297 173 328 334 8 44 10 604 8 2100 -3
                                2301 5 2072 -85 2255 -39 81 -60 109 -142 191 -82 82 -110 103 -191 142 -183
                                90 28 82 -2115 84 -1551 1 -1912 -1 -1965 -12z m3855 -170 c226 -48 413 -236
                                460 -462 13 -63 15 -332 13 -2095 -3 -1951 -4 -2024 -22 -2058 -26 -49 -74
                                -98 -121 -123 l-40 -22 -2205 0 c-2131 0 -2206 1 -2240 19 -49 26 -98 74 -123
                                121 l-22 40 -3 2023 c-2 2244 -7 2097 64 2242 73 150 239 279 404 314 100 22
                                3733 22 3835 1z"/>
                                   <path d="M2335 4774 c-515 -38 -872 -104 -1275 -237 -274 -91 -657 -257 -697
                                -303 l-23 -25 0 -1215 c0 -1293 -1 -1260 45 -1278 22 -8 4328 -8 4350 0 46 18
                                45 -15 45 1277 0 1192 0 1215 -20 1239 -21 27 -219 122 -435 208 -411 165
                                -876 276 -1340 320 -114 11 -562 21 -650 14z m485 -174 c598 -37 1167 -180
                                1673 -418 l117 -55 0 -1123 0 -1124 -2050 0 -2050 0 0 1123 0 1124 108 51
                                c466 221 1027 369 1552 412 270 21 423 24 650 10z"/>
                                   <path d="M2380 4433 c-502 -26 -967 -133 -1415 -324 -183 -78 -257 -118 -272
                                -149 -10 -19 -13 -227 -13 -937 l0 -911 26 -31 26 -31 1828 0 1828 0 26 31 26
                                31 0 911 c0 710 -3 918 -13 937 -32 65 -488 251 -842 345 -381 100 -832 148
                                -1205 128z m390 -173 c506 -31 972 -147 1423 -354 l77 -35 0 -826 0 -825 -516
                                2 -516 3 -19 73 c-22 87 -48 148 -90 209 l-31 46 181 181 181 181 43 -43 c53
                                -52 99 -57 138 -16 22 24 24 34 27 152 4 144 -8 199 -48 219 -18 10 -66 13
                                -172 11 -137 -3 -149 -5 -170 -25 -37 -37 -30 -85 20 -136 l42 -42 -183 -182
                                -182 -182 -25 21 c-38 31 -157 85 -230 104 -85 22 -236 22 -320 1 -36 -10
                                -103 -36 -150 -58 -69 -34 -101 -58 -170 -128 -98 -98 -151 -192 -180 -313
                                l-17 -73 -516 -3 -517 -2 0 825 0 824 77 37 c423 198 928 325 1400 353 190 12
                                261 12 443 1z m-70 -1637 c88 -24 151 -62 221 -132 68 -68 121 -160 134 -233
                                l7 -38 -502 0 -502 0 7 38 c11 60 62 156 112 213 135 149 328 206 523 152z"/>
                                   <path d="M2523 4085 c-39 -17 -53 -59 -53 -167 0 -43 -2 -78 -5 -78 -2 0 -32
                                18 -66 40 -34 22 -69 41 -78 41 -78 6 -128 -67 -87 -127 26 -37 294 -207 326
                                -207 32 0 300 170 326 207 20 29 17 69 -7 100 -31 40 -79 35 -157 -14 -34 -22
                                -65 -40 -67 -40 -3 0 -5 35 -5 78 0 110 -13 150 -55 168 -40 16 -32 17 -72 -1z"/>
                                   <path d="M1735 3985 c-52 -51 -16 -145 54 -145 46 0 85 39 85 85 0 74 -88 112
                                -139 60z"/>
                                   <path d="M3269 3981 c-17 -18 -29 -40 -29 -56 0 -38 50 -85 90 -85 44 0 80 39
                                80 85 0 79 -84 112 -141 56z"/>
                                   <path d="M1395 3817 c-57 -48 -20 -147 55 -147 16 0 41 10 55 23 57 48 20 147
                                -55 147 -16 0 -41 -10 -55 -23z"/>
                                   <path d="M3614 3816 c-58 -49 -20 -146 57 -146 43 0 79 39 79 85 0 74 -80 110
                                -136 61z"/>
                                   <path d="M1075 3659 c-32 -19 -45 -41 -45 -79 0 -33 10 -46 109 -143 92 -91
                                113 -107 140 -107 45 0 81 38 81 86 0 34 -10 47 -102 141 -107 107 -141 126
                                -183 102z"/>
                                   <path d="M3975 3660 c-31 -13 -215 -205 -216 -226 -4 -62 30 -104 83 -104 26
                                0 49 17 139 107 94 92 109 111 109 139 0 46 -9 63 -41 79 -31 17 -45 18 -74 5z"/>
                                   <path d="M620 1529 c-111 -22 -199 -92 -248 -198 -37 -80 -37 -192 0 -272 51
                                -111 137 -177 258 -200 143 -26 284 47 358 186 24 45 27 61 27 150 0 89 -3
                                105 -27 150 -71 134 -227 212 -368 184z m159 -197 c46 -34 71 -81 71 -134 0
                                -184 -246 -240 -321 -74 -23 52 -23 90 0 142 43 93 168 127 250 66z"/>
                                   <path d="M2014 1526 c-18 -8 -36 -27 -44 -46 -13 -32 -11 -38 257 -575 247
                                -494 274 -543 303 -555 27 -11 36 -11 63 2 29 14 61 73 300 553 229 457 267
                                541 262 566 -13 58 -94 82 -138 41 -12 -11 -119 -215 -237 -452 -118 -236
                                -217 -430 -220 -430 -3 0 -102 194 -220 430 -118 237 -224 439 -235 449 -29
                                27 -56 32 -91 17z"/>
                                   <path d="M4290 1529 c-111 -22 -199 -92 -249 -198 -21 -46 -26 -70 -26 -136 0
                                -71 4 -89 33 -147 59 -121 168 -191 296 -192 107 0 167 24 242 98 74 74 104
                                142 104 241 0 210 -196 374 -400 334z m140 -184 c57 -29 90 -87 90 -156 0 -49
                                -19 -88 -60 -123 -148 -124 -351 55 -250 221 44 73 140 98 220 58z"/>
                                   <path d="M1223 828 c-24 -27 -30 -52 -20 -90 15 -56 32 -58 419 -58 381 0 392
                                2 416 54 17 33 9 69 -20 96 -21 19 -34 20 -398 20 l-377 0 -20 -22z"/>
                                   <path d="M3232 835 c-83 -36 -144 -116 -156 -205 -8 -54 8 -89 48 -108 51 -25
                                116 14 116 69 0 42 45 89 85 89 53 0 75 -26 91 -109 16 -86 46 -138 105 -181
                                125 -90 301 -48 372 90 39 77 40 145 3 177 -15 13 -40 23 -55 23 -30 0 -81
                                -34 -81 -53 0 -72 -53 -125 -111 -112 -38 9 -57 39 -68 106 -13 74 -24 100
                                -63 144 -65 75 -199 108 -286 70z"/>
                                   <path d="M1226 488 c-38 -35 -39 -86 -1 -123 51 -52 136 -15 138 59 2 73 -83
                                112 -137 64z"/>
                                   <path d="M1565 485 c-50 -49 -24 -130 46 -141 28 -5 41 -1 62 17 41 36 45 78
                                12 117 -34 41 -84 44 -120 7z"/>
                                   <path d="M1905 485 c-48 -47 -25 -125 42 -141 53 -14 113 46 99 99 -16 66 -95
                                89 -141 42z"/>
                               </g>
                           </svg>
                           Continuité de service
                        </a>
                       <a routerLink="{{baseUrl()}}/abonnement" routerLinkActive="active"  class="menu-item-text">
                           <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="2vw" height="2vh" viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet">

                               <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                  fill="#ffffff" stroke="none">
                                   <path d="M620 5105 c-160 -33 -267 -92 -391 -215 -82 -81 -102 -108 -142 -190
                                -90 -183 -82 47 -85 -2260 -2 -1541 0 -2059 9 -2100 34 -160 169 -296 327
                                -329 75 -16 4369 -16 4444 0 160 34 297 173 328 334 8 44 10 604 8 2100 -3
                                2301 5 2072 -85 2255 -39 81 -60 109 -142 191 -82 82 -110 103 -191 142 -183
                                90 28 82 -2115 84 -1551 1 -1912 -1 -1965 -12z m3855 -170 c226 -48 413 -236
                                460 -462 13 -63 15 -332 13 -2095 -3 -1951 -4 -2024 -22 -2058 -26 -49 -74
                                -98 -121 -123 l-40 -22 -2205 0 c-2131 0 -2206 1 -2240 19 -49 26 -98 74 -123
                                121 l-22 40 -3 2023 c-2 2244 -7 2097 64 2242 73 150 239 279 404 314 100 22
                                3733 22 3835 1z"/>
                                   <path d="M2335 4774 c-515 -38 -872 -104 -1275 -237 -274 -91 -657 -257 -697
                                -303 l-23 -25 0 -1215 c0 -1293 -1 -1260 45 -1278 22 -8 4328 -8 4350 0 46 18
                                45 -15 45 1277 0 1192 0 1215 -20 1239 -21 27 -219 122 -435 208 -411 165
                                -876 276 -1340 320 -114 11 -562 21 -650 14z m485 -174 c598 -37 1167 -180
                                1673 -418 l117 -55 0 -1123 0 -1124 -2050 0 -2050 0 0 1123 0 1124 108 51
                                c466 221 1027 369 1552 412 270 21 423 24 650 10z"/>
                                   <path d="M2380 4433 c-502 -26 -967 -133 -1415 -324 -183 -78 -257 -118 -272
                                -149 -10 -19 -13 -227 -13 -937 l0 -911 26 -31 26 -31 1828 0 1828 0 26 31 26
                                31 0 911 c0 710 -3 918 -13 937 -32 65 -488 251 -842 345 -381 100 -832 148
                                -1205 128z m390 -173 c506 -31 972 -147 1423 -354 l77 -35 0 -826 0 -825 -516
                                2 -516 3 -19 73 c-22 87 -48 148 -90 209 l-31 46 181 181 181 181 43 -43 c53
                                -52 99 -57 138 -16 22 24 24 34 27 152 4 144 -8 199 -48 219 -18 10 -66 13
                                -172 11 -137 -3 -149 -5 -170 -25 -37 -37 -30 -85 20 -136 l42 -42 -183 -182
                                -182 -182 -25 21 c-38 31 -157 85 -230 104 -85 22 -236 22 -320 1 -36 -10
                                -103 -36 -150 -58 -69 -34 -101 -58 -170 -128 -98 -98 -151 -192 -180 -313
                                l-17 -73 -516 -3 -517 -2 0 825 0 824 77 37 c423 198 928 325 1400 353 190 12
                                261 12 443 1z m-70 -1637 c88 -24 151 -62 221 -132 68 -68 121 -160 134 -233
                                l7 -38 -502 0 -502 0 7 38 c11 60 62 156 112 213 135 149 328 206 523 152z"/>
                                   <path d="M2523 4085 c-39 -17 -53 -59 -53 -167 0 -43 -2 -78 -5 -78 -2 0 -32
                                18 -66 40 -34 22 -69 41 -78 41 -78 6 -128 -67 -87 -127 26 -37 294 -207 326
                                -207 32 0 300 170 326 207 20 29 17 69 -7 100 -31 40 -79 35 -157 -14 -34 -22
                                -65 -40 -67 -40 -3 0 -5 35 -5 78 0 110 -13 150 -55 168 -40 16 -32 17 -72 -1z"/>
                                   <path d="M1735 3985 c-52 -51 -16 -145 54 -145 46 0 85 39 85 85 0 74 -88 112
                                -139 60z"/>
                                   <path d="M3269 3981 c-17 -18 -29 -40 -29 -56 0 -38 50 -85 90 -85 44 0 80 39
                                80 85 0 79 -84 112 -141 56z"/>
                                   <path d="M1395 3817 c-57 -48 -20 -147 55 -147 16 0 41 10 55 23 57 48 20 147
                                -55 147 -16 0 -41 -10 -55 -23z"/>
                                   <path d="M3614 3816 c-58 -49 -20 -146 57 -146 43 0 79 39 79 85 0 74 -80 110
                                -136 61z"/>
                                   <path d="M1075 3659 c-32 -19 -45 -41 -45 -79 0 -33 10 -46 109 -143 92 -91
                                113 -107 140 -107 45 0 81 38 81 86 0 34 -10 47 -102 141 -107 107 -141 126
                                -183 102z"/>
                                   <path d="M3975 3660 c-31 -13 -215 -205 -216 -226 -4 -62 30 -104 83 -104 26
                                0 49 17 139 107 94 92 109 111 109 139 0 46 -9 63 -41 79 -31 17 -45 18 -74 5z"/>
                                   <path d="M620 1529 c-111 -22 -199 -92 -248 -198 -37 -80 -37 -192 0 -272 51
                                -111 137 -177 258 -200 143 -26 284 47 358 186 24 45 27 61 27 150 0 89 -3
                                105 -27 150 -71 134 -227 212 -368 184z m159 -197 c46 -34 71 -81 71 -134 0
                                -184 -246 -240 -321 -74 -23 52 -23 90 0 142 43 93 168 127 250 66z"/>
                                   <path d="M2014 1526 c-18 -8 -36 -27 -44 -46 -13 -32 -11 -38 257 -575 247
                                -494 274 -543 303 -555 27 -11 36 -11 63 2 29 14 61 73 300 553 229 457 267
                                541 262 566 -13 58 -94 82 -138 41 -12 -11 -119 -215 -237 -452 -118 -236
                                -217 -430 -220 -430 -3 0 -102 194 -220 430 -118 237 -224 439 -235 449 -29
                                27 -56 32 -91 17z"/>
                                   <path d="M4290 1529 c-111 -22 -199 -92 -249 -198 -21 -46 -26 -70 -26 -136 0
                                -71 4 -89 33 -147 59 -121 168 -191 296 -192 107 0 167 24 242 98 74 74 104
                                142 104 241 0 210 -196 374 -400 334z m140 -184 c57 -29 90 -87 90 -156 0 -49
                                -19 -88 -60 -123 -148 -124 -351 55 -250 221 44 73 140 98 220 58z"/>
                                   <path d="M1223 828 c-24 -27 -30 -52 -20 -90 15 -56 32 -58 419 -58 381 0 392
                                2 416 54 17 33 9 69 -20 96 -21 19 -34 20 -398 20 l-377 0 -20 -22z"/>
                                   <path d="M3232 835 c-83 -36 -144 -116 -156 -205 -8 -54 8 -89 48 -108 51 -25
                                116 14 116 69 0 42 45 89 85 89 53 0 75 -26 91 -109 16 -86 46 -138 105 -181
                                125 -90 301 -48 372 90 39 77 40 145 3 177 -15 13 -40 23 -55 23 -30 0 -81
                                -34 -81 -53 0 -72 -53 -125 -111 -112 -38 9 -57 39 -68 106 -13 74 -24 100
                                -63 144 -65 75 -199 108 -286 70z"/>
                                   <path d="M1226 488 c-38 -35 -39 -86 -1 -123 51 -52 136 -15 138 59 2 73 -83
                                112 -137 64z"/>
                                   <path d="M1565 485 c-50 -49 -24 -130 46 -141 28 -5 41 -1 62 17 41 36 45 78
                                12 117 -34 41 -84 44 -120 7z"/>
                                   <path d="M1905 485 c-48 -47 -25 -125 42 -141 53 -14 113 46 99 99 -16 66 -95
                                89 -141 42z"/>
                               </g>
                           </svg>
                           Parc de clients
                       </a>
                   </li>
                   <li  *ngIf="baseUrl() === 'geographique'"><a routerLink="{{baseUrl()}}/evenements" routerLinkActive="active"  class="menu-item-text" (click)="displayRightMenu()">
                       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="2vw" height="2vh" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                           <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              fill="#ffffff" stroke="none">
                               <path d="M0 2560 l0 -2560 2560 0 2560 0 0 2560 0 2560 -2560 0 -2560 0 0
                                -2560z m2940 2262 c691 -124 1257 -519 1606 -1119 277 -476 371 -1059 259
                                -1608 -140 -689 -610 -1291 -1250 -1600 -301 -145 -579 -213 -922 -222 -356
                                -10 -663 46 -970 177 -726 311 -1235 962 -1368 1750 -22 130 -31 448 -16 591
                                55 529 287 1017 661 1389 364 363 819 586 1330 655 156 21 519 14 670 -13z"/>
                               <path d="M2839 4101 c-14 -5 -35 -13 -46 -19 -19 -11 -1204 -1616 -1233 -1671
                                -18 -35 -8 -121 18 -158 43 -60 59 -63 386 -63 163 0 296 -2 296 -5 0 -4 -41
                                -228 -90 -500 -49 -272 -90 -507 -90 -522 0 -41 40 -104 79 -127 45 -26 117
                                -26 161 0 24 14 186 242 631 889 329 478 603 880 609 893 29 64 2 148 -63 196
                                -27 20 -42 21 -334 24 -233 2 -304 6 -301 15 3 7 45 213 93 458 l89 446 -18
                                47 c-28 73 -116 119 -187 97z"/>

                           </g>
                       </svg>
                       Evènements
                   </a></li>
               </ul>

        </div>
    </header>
    <main>
        <router-outlet></router-outlet>
    </main>
    </nb-layout-column>
</nb-layout>
