export function getYesterdayDate(){
    const todayDate = new Date();
    const yesterdayDate = new Date();
    const todaysDayOfMonth = todayDate.getDate();
    yesterdayDate.setDate(todaysDayOfMonth - 1);
    return yesterdayDate;
}
 export function extractType(tab){
    let result = [];
    let type = [];
     tab.forEach((element) => {
         if(!type.includes(element.code_type))
         {
             type.push(element.code_type);
             result.push({type: element.type, code: element.code_type});
         }
     });
     return result
 }


export function formatTime(seconds: number): string {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    if (days > 0) {
        return `${days} , ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
}


