<nb-card  *ngIf="compteur">
    <nb-card-header *ngIf="isPoste">
        <div>
            <h6 class="info_client">
                <span *ngIf="compteur.nom">{{compteur.nom}} - </span>
                <span *ngIf="compteur.numero_dcu"> DCU: {{compteur.numero_dcu}} - </span>
               <span>Nombre de clients:  {{compteur.nombre_compteur_cpl}} - Puissance Transfo: {{compteur.puissance_nominal_transfo}} - </span>
                <span *ngIf="compteur.min_taux_charge"> Min: {{compteur.min_taux_charge}} % - </span>
                <span *ngIf="compteur.max_taux_charge"> Max: {{compteur.max_taux_charge}} % - </span>
                <span *ngIf="compteur.moyenne_taux_charge"> Moyenne: {{compteur.moyenne_taux_charge}} % </span>
            </h6>
        </div>
        <h5 *ngIf="compteur.titre" class="title">{{compteur.titre}}</h5>
    </nb-card-header>
    <nb-card-header *ngIf="!isPoste">
        <div>
            <h6 class="info_client">{{compteur.name}} - {{compteur.adresse}} - {{compteur.num_compteur}} - {{compteur.reference_client}} -
                {{compteur.type_compteur}} <span *ngIf="compteur.mode_communication">- {{compteur.mode_communication}}</span> <span *ngIf="compteur.date_pose "> - Posé le {{compteur.date_pose | date: 'dd/MM/yyyy'}}</span></h6>
        </div>
        <h5 *ngIf="compteur.title" class="title">{{compteur.title}}</h5>
        <h5 *ngIf="compteur.titre" class="title">{{compteur.titre}}</h5>
        <h5 class="title">Du {{compteur.start_date | date: 'dd/MM/yyyy'}}  au {{compteur.end_date | date: 'dd/MM/yyyy'}}</h5>
    </nb-card-header>
    <nb-card-body>
        <div *ngIf="type === 'tension' && !isPoste">
            <app-courbe-qualimetrie *ngIf="compteur"
                                    [data]="compteur">
            </app-courbe-qualimetrie>
        </div>
        <div *ngIf="type === 'coupure'">
            <app-tableaux
                    [data]="compteur"
            >
            </app-tableaux>
        </div>
        <div *ngIf="type === 'consommation'">
           <app-conso-details *ngIf="compteur" [data]="compteur"></app-conso-details>
        </div>

        <div *ngIf="type === 'evenements'">
           <app-details-events *ngIf="compteur" [data]="compteur"></app-details-events>
        </div>

        <div *ngIf="type === 'tension' && isPoste" >
            <app-details-postes [data]="compteur"></app-details-postes>
        </div>

    </nb-card-body>
</nb-card>
