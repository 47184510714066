import { Component, Input } from '@angular/core';
import * as Highcharts from "highcharts";
import * as _ from 'lodash';
import { SALES_DATA } from "../../../../../utils/charts/horizontal-column-option";

@Component({
  selector: 'app-histogramme-horizontale',
  templateUrl: './histogramme-horizontale.component.html',
  styleUrls: ['./histogramme-horizontale.component.css']
})

export class HistogrammeHorizontaleComponent {
  highcharts = Highcharts;
  salesDataOptions= _.cloneDeep(SALES_DATA);
  _data;
  _type;
  _salesData;
  chartUpdateFlag = false;

  @Input() set salesData(value){
    this.salesDataOptions.series[0].data = [];
    this.salesDataOptions.xAxis.categories = [];
    if(value.length > 0){
      this._salesData = value;
      this._salesData.forEach((element) => {
        this.salesDataOptions.series[0].data.push(Number(element[3]));
        this.salesDataOptions.xAxis.categories.push(element[2]);
      });

      this.chartUpdateFlag = true;
    }
  }

  get salesData(){
    return this._salesData;
  }
}
