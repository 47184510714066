import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import * as _ from 'lodash';
import {COURBE_MOYENNE} from "../../../../../utils/charts/courbe-moyenne-options";

@Component({
  selector: 'app-courbe-moyenne',
  templateUrl: './courbe-moyenne.component.html',
  styleUrls: ['./courbe-moyenne.component.css']
})
export class CourbeMoyenneComponent {

  highcharts = Highcharts;
  chartOptions =  _.cloneDeep(COURBE_MOYENNE);
  _data;
  chartUpdateFlag = false;

  @Input() set data(value) {
    this.chartOptions.series[0].data = [];
    this._data = value;

    this.chartUpdateFlag = true;
  }

  get data() {
    return this._data;
  }

}
