import {Component, OnInit} from '@angular/core';
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-consommation-vue-graphique',
    templateUrl: './consommation-vue-graphique.component.html',
    styleUrls: ['./consommation-vue-graphique.component.css']
})
export class ConsommationVueGraphiqueComponent implements OnInit{
    energieVendue: any = [];
    repartitionVente: any = [];
    topTroisVendeurs: any;
    energieNonDistribuee: any = [];
    consoVeilleGWH;
    consoVeilleMRU;
    consoMoisCoursGWH;
    consoMoisCoursMRU;
    date: any;
    dateDebut: any;
    dateFin: any;
    first = true;
    perimeterCode;
    exceptionalTitle = '';
    private perimeterType;

    constructor(
        private service: MainScreenService,
        private store: Store<any>,
        private datePipe :DatePipe) {
    }

    loading;

    subscription!: Subscription;

    netSubs!: Subscription;

    type = 'coupure';

    periodeConso = 'annee';

    periode = 'jour';

    typeConso = 'kWh'

    consoTotaleMois;

    conso

    transactionParMois;

    consoParAgence;

    venteParAgence;

    venteParMois;

    dettesToDisplay: any = [];

    consoParAgenceKWh: any;

    consoParAgenceMRU: any;

    colors = ['#01ABAE', 'rgba(189, 87, 65, 1)', '#47C471', '#D0C000', '#60BA9C', '#DEDFDE', 'rgba(103, 189, 140, 0.21)'];

    colorsTarif = ['#01ABAE', 'rgba(189, 87, 65, 1)', '#47C471', '#D0C000', '#60BA9C', '#DEDFDE', 'rgba(103, 189, 140, 0.21)','rgba(103, 189, 140, 0.21)', 'rgba(20, 18, 181, 1)', 'rgba(26, 172, 204, 1)'];

    colorsTransaction = ['#D0C000', 'rgba(26, 172, 204, 1)', '#8F77FA', '#DEDFDE', 'rgba(189, 87, 65, 1)', '#47C471', '#01ABAE'];

    colorsRepartition = ['#D0C000', '#8F77FA', 'rgba(189, 87, 65, 1)', 'rgba(26, 172, 204, 1)', '#47C471', 'rgba(26, 172, 204, 1)'];

    consoParTarif: any;

    consoParActivite: any;

    getData;

  colorsRepartitionConso = ['#01ABAE',  '#47C471', 'rgba(189, 87, 65, 1)'];
  sommeConsoGwh:any
  sommeConsoMru:any
  consoFonctionCalendrie: any;
  consoMoyen: any;
  consoMoyenGWH: any;
  consoMoyenMRU: any;
  consoGlobal: any;
  consoVeille: any;
  consoMoisEnCours: any;
  transactionParAgence;

  repartitionParAgence;

  dettes;
  typeByChose: any;
  tempSubscription!: Subscription;
  periodeTitle: string = 'des 30 derniers jours';



    ngOnInit(): void {
        this.energieVendue = [];
        this.repartitionVente = [];
        this.tempSubscription = this.store
            ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
        this.netSubs = this.store.pipe(select('header_store'))?.subscribe(this.preSetAgency.bind(this));

    }


    preSetAgency(state: any) {
        this.typeByChose = state.type ==='global' ? 'agence' : 'secteur';
        this.perimeterType = state.type;
        this.perimeterCode = state.code;
        this.getAllFunctions();
        this.first = false;
    }

    getAllFunctions(){
        this.getConsoParAgence(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getConsoParTarif(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getConsoParActivite(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getConsoTotaleMois(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getVenteParAgence(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getVenteParMois(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getConsoParAnnuel(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin)
        this.getConsosHier(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin)
        this.getConsosMois(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin)
        this.getConsosMoyenne(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin)
        this.getTransactionParMois(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getTransactionParAgence(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getDettes(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getEnergieNonConsommee(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getEnergieVendue(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getRepartitionVente(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
        this.getTopTroisVendeurs(this.perimeterType, this.perimeterCode, this.date, this.dateDebut, this.dateFin);
    }

    preSetDate(state: any) {
        this.date= state.selected_date;
        this.dateDebut = state.start_date;
        this.dateFin = state.end_date;
        this.periode = state.period;
        this.getPeriodeTitle();
        if(!this.first){
            this.getAllFunctions();
        }
    }

    getConsoParAgence(type,code, date, date_debut, date_fin) {

    this.loading = true;
    this.service.getConsoGraphique('conso_par_agence', type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
            this.loading = false;
            this.consoParAgenceKWh = {type: 'kWh', data: data};
            this.consoParAgenceMRU = {type: 'MRU', data: data};
            this.conso = data;
        },
        (error) => {
          this.loading = false;
        },
    );
  }

  getConsoParAnnuel(type,code, date, date_debut, date_fin) {
    this.loading = true;
    this.service.getConsoAnnuelHoraire('repartition_consommation', type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
           if(data){
               this.getData = data[0]
               this.consoFonctionCalendrie = [this.getData[2], this.getData[3], this.getData[4]]
               this.sommeConsoGwh = this.getData[1]
               this.sommeConsoMru = this.getData[0]
           }
            this.loading = false;

        },
        (error) => {
          this.loading = false;
        },
    );
  }

  getConsoParTarif(type,code, date, date_debut, date_fin) {
    this.loading = true;
    this.service.getConsoGraphique('conso_par_tarif', type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
            this.loading = false;
            this.consoParTarif = {data: data, type: 'tarif'};
        },
        (error) => {
            this.loading = false;
        },
    );
  }

  getConsoParActivite(type,code, date, date_debut, date_fin) {
    this.loading = true;
    this.service.getConsoGraphique('conso_par_code_activite', type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
            this.loading = false;
            this.consoParActivite = {data: data, type: 'activite'};
        },
        (error) => {
            this.loading = false;
        },
    );
  }

  getConsoTotaleMois(type,code, date, date_debut, date_fin) {
    this.loading = true;
    this.service.getConsoGraphique('conso_totale_mois', type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
          this.loading = false;
          this.consoTotaleMois = data;
        },
        (error) => {
          this.loading = false;
        },
    );
  }

    getVenteParAgence(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('transaction_par_agence', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.venteParAgence = data;
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getTransactionParAgence(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('vente_par_agence', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.transactionParAgence = {data: data, type: 'transaction'};
                this.repartitionParAgence = {data: data, type: 'repartition'};
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getVenteParMois(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.venteParMois = [];
        this.service.getConsoGraphique('vente_par_mois', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.venteParMois = data;
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getDettes(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.dettes = [];
        this.dettesToDisplay = [];
        this.service.getConsoGraphique('dettes', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.dettes = data;
                this.dettesToDisplay = data[0];
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }


    getTransactionParMois(type,code, date, date_debut, date_fin){
        this.loading = true;
        this.service.getConsoGraphique('transaction_par_mois', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.transactionParMois = data;
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            },
        );
    }


    getEnergieNonConsommee(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('energie_vendue_non_distribuee', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                if(data.length > 0){
                    this.loading = false;
                    this.energieNonDistribuee = data[0];
                }
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getEnergieVendue(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('energie_vendue', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.loading = false;
                this.energieVendue = data[0];
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getRepartitionVente(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('repartition_des_ventes', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.loading = false;
                this.repartitionVente = data[0];
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getTopTroisVendeurs(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('rang_agence', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.loading = false;
                this.topTroisVendeurs = data;
            },
            (error) => {
                this.loading = false;
            },
        );
    }


    getConsosHier(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('conso_hier', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.loading =false
                this.consoVeille = data[0];
                if(this.consoVeille){
                    this.consoVeille[0] !== null ? this.consoVeilleGWH = Number(this.consoVeille[0]).toFixed(2) : null;
                    this.consoVeille[1] !== null ? this.consoVeilleMRU = Number(this.consoVeille[1]).toFixed(2) : null;
                }
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getConsosMoyenne(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('moyenne_globale', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.loading =false;
                if(data.length > 0){
                    this.consoMoyen = data[0];
                    this.consoMoyenGWH = this.consoMoyen[0];
                    this.consoMoyenMRU = this.consoMoyen[1];
                }
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    getConsosMois(type,code, date, date_debut, date_fin) {
        this.loading = true;
        this.service.getConsoGraphique('conso_mois_cours', type, code, this.periode, date, date_debut, date_fin).subscribe(
            (data) => {
                this.loading =false
                this.consoMoisEnCours  = data[0]
                if(this.consoMoisEnCours){
                    this.consoMoisCoursGWH = this.consoMoisEnCours[0] !== null ? Number(this.consoMoisEnCours[0]).toFixed(2) : null;
                    this.consoMoisCoursMRU = this.consoMoisEnCours[1] !== null ? Number(this.consoMoisEnCours[1]).toFixed(2) : null;
                }
            },
            (error) => {
                this.loading = false;
            },
        );
    }

    changeType() {
        if(this.typeConso === 'kWh'){
            this.typeConso = 'MRU';
        } else {
            this.typeConso = 'kWh';
        }
    }

    getPeriodeTitle(){
        switch (this.periode) {
            case 'intervalle' :
                this.periodeTitle = `du ${this.datePipe.transform(new Date(this.dateDebut), "dd/MM/yy")} au ${this.datePipe.transform(new Date(this.dateFin), "dd/MM/yy")}`;
                this.exceptionalTitle = this.periodeTitle;
                break;
            case 'mois' :
                this.periodeTitle = `de ${this.datePipe.transform(new Date(this.date), "MMM yyyy", "fr-FR")}`;
                this.exceptionalTitle = this.periodeTitle;
                break;
            default:
                this.periodeTitle =`du ${this.datePipe.transform(new Date(this.date), "dd/MM/yy")}`;
                this.exceptionalTitle = 'des 30 derniers jours';
                break;
        }
    }
}
