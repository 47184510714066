import { Action } from '@ngrx/store';

export const SET_TEMPORAL_NAVIGATION_DATE = '[DATE_NAVIGATION] DATE';

export class SetTemporalDate implements Action {
    readonly type = SET_TEMPORAL_NAVIGATION_DATE;

    constructor(public payload: {
        period: string;
        start: any;
        end: any;
        date: any }) {}
}

export type Actions = SetTemporalDate;
