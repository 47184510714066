<div id="legend" >
    <button  class="collapsible">Légende  {{titre}}</button>
    <div  *ngIf="!isEvent"  class="content">
        <div id="transparent" class="line"(click)="displayDetails(all)">
            <div style="background-color:transparent"  class="color-square"></div>
            <span class="text">TOUTES LES CATÉGORIES</span>
        </div>
       <div *ngFor="let legendObject of legend" (dblclick)="dbClick(legendObject)"  (click)="displayDetails(legendObject)">
           <div id="{{legendObject.couleur}}" class="line">
               <div style="background-color:{{legendObject.couleur}}"  class="color-square"></div>
               <span class="text"> {{legendObject.description }}</span>
           </div>
       </div>
        <div class="line"></div>
    </div>
    <div  *ngIf="isEvent" class="content">
        <div class="content1">
            <div  *ngFor="let legendObject of eventsLegend; let  i = index" >
                <button [ngClass]="legendObject.isSelected ? 'type selected-categorie' : 'type categorie'"  class="type">{{legendObject.type  }}</button>
                <div    *ngFor="let legend of legendObject.data" (click)="displayEventDetails(legend, legendObject.code, i)" >
                    <div id="{{legend.description}}" class="line">
                        <div style="background-color:{{legend.couleur}}"  class="color-square"></div>
                        <span class="text"> {{legend.description  }}</span>
                    </div>
                </div>

            </div>
        </div>

        <div class="line"></div>
    </div>
    <button *ngIf="date"  class="date">{{date}}</button>
</div>

