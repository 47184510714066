
<nb-card>
    <nb-card-header>
        <h6>Liste des anomalies</h6>
    </nb-card-header>
    <nb-card-body>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr class="left">
                    <th scope="col">Date</th>
                    <th scope="col">Durée [jours, hh:mn:ss]</th>
                    <th scope="col">Type</th>
                    <th scope="col">Évènement</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let info of rowData">
                    <td> {{info.start_date | date: "dd MMMM yyyy hh:mm"}} </td>
                    <td>{{info.duree}}</td>
                    <td>{{info.type}}</td>
                    <td>{{info.categorie}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </nb-card-body>
</nb-card>
