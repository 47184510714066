import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-repartition-vente',
  templateUrl: './repartition-vente.component.html',
  styleUrls: ['./repartition-vente.component.scss']
})
export class RepartitionVenteComponent {

  _data;
  montantRecouvre: any;
  montantRembourse: any;
  nombreClient: any;
  prepaid: any;
  postpaid: any;
  montant = '--';

  @Input() set data(value){
   if(value.length > 0){
     this._data = value;
     this.montant = this._data[3];
     this.montantRecouvre = this._data[5]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' MRU';
     this.montantRembourse = this._data[6]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' MRU';
     this.nombreClient = this._data[7]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
     this.prepaid = this._data[8]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
     this.postpaid = this._data[9]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
   }
  }

  get data(){
    return this._data;
  }

}
