import {Component, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import * as TemporalActions from "../../actions/temporal-navigation.actions";
import {DatePipe} from "@angular/common";
import {getYesterdayDate} from "../../../../utils/functions";

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {
  selectedDate;
  startDate;
  endDate;
  pipe;

  tempSubscription!: Subscription;
  periode = 'jour';
  _clicklable = true;
  date: string;

  @Input() set clickable(value){
    this._clicklable = value;
    if(!this._clicklable){
      if(this.periode === 'jour'){
        this.periode = 'jour';
        this.onDateChange(this.selectedDate);
      }else {
        this.periode = 'jour';
        this.onDateChange(getYesterdayDate());
      }

    }
  }

  get clickable(){
    return this._clicklable ;
  }

  constructor(private store: Store<any>, private datePipe :DatePipe) {
    this.date = `${this.datePipe.transform(getYesterdayDate(), "dd/MM/yy", "fr-FR")}`;
    this.tempSubscription = this.store
        ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
  }

  pickDate(id) {
   if(this.clickable || id === 'jour'){
     document?.getElementById(id).click();
     this.periode = id;
   }
  }

  onDateChange(event: any) {
    this.selectedDate = this.datePipe.transform(event, "yyyy-MM-dd");
    switch (this.periode) {
      case 'mois':
        this.date = `${this.datePipe.transform(new Date(this.selectedDate), "MMMM yyyy", "fr-FR")}`;
        break;
      case '30_derniers_jours':
        this.date =`30 derniers jours`
        break;
      default:
        this.date= `${this.datePipe.transform(new Date(this.selectedDate), "dd/MM/yy", "fr-FR")}`;
        break;
    }
    this.setDate();
  }

  preSetDate(state: any) {
    this.selectedDate = state.selected_date;
    this.startDate = state.start_date;
    this.endDate = state.end_date;
    this.periode = state.period;

  }

  setDate() {
    this.store.dispatch(new TemporalActions.SetTemporalDate({date: this.selectedDate, start: this.startDate, end: this.endDate, period: this.periode}));
  }

  onRangeChange(event: any) {
    if(event.start && event.end){
      this.startDate = this.datePipe.transform(event.start, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(event.end, "yyyy-MM-dd")
      this.date = `Du ${this.datePipe.transform(new Date(this.startDate), "dd/MM/yy", "fr-FR")} au ${this.datePipe.transform(new Date(this.endDate), "dd/MM/yy", "fr-FR")}`
      this.setDate();
    }
  }

  pickLastDays(id) {
    if(this.clickable ) {
      this.periode = id
      this.onDateChange(new Date());
    }
  }

  filterFn = (date) => date.getDate() === 1;
  maxDate = new Date();

}
