<div class="table-responsive table-container">
    <table class="table">
        <thead>
        <tr class="left">
            <th scope="col">Zone</th>
            <th scope="col">Conso Totale</th>
            <th scope="col">Coût par agence</th>
            <th scope="col">Nombre de compteurs</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let info of data">
            <td> {{info[0]}}</td>
            <td> {{info[4] | number: '1.2-2'}}</td>
            <td> {{info[5] | number: '1.2-2'}} </td>
            <td>{{info[6]}}</td>
        </tr>
        </tbody>
    </table>
    <label class="nodata" *ngIf="data.length <= 0">Pas de Données</label>
</div>
