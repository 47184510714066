import {Component, OnInit} from '@angular/core';
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-coupure-vue-graphique',
  templateUrl: './coupure-vue-graphique.component.html',
  styleUrls: ['coupure-vue-graphique.component.css']
})
export class CoupureVueGraphiqueComponent implements OnInit{

  constructor(private service: MainScreenService, private store: Store<any>) {
  }

  loading = false;

  subscription!: Subscription;

  netSubs!: Subscription;



  data=   [
    {

      "mois": "2023-03-01",
      "groupe" :"GR1",
      "nombre" :16.0
  },
    {

      "mois": "2023-04-01",
      "groupe" :"GR1",
      "nombre" :19.0
    },

    {

      "mois": "2023-03-01",
      "groupe" :"GR2",
      "nombre" :192.0
    },
    {

      "mois": "2023-04-01",
      "groupe" :"GR2",
      "nombre" :192.0
    },
    {

      "mois": "2023-04-01",
      "groupe" :"GR3",
      "nombre" :180.0
    },
    {

      "mois": "2023-03-01",
      "groupe" :"GR3",
      "nombre" :0.0
    },

    {

      "mois": "2023-03-06",
      "groupe" :"GR1",
      "nombre" :192.0
    }
  ]

  dureeMoyenne: any = {type: 'coupures', moy: [12, 20, 30,45], mois: ['01/23', '02/23', '04/23', '05/23']};
  typeCoupure: any

  axis: any;
  yaxis: any
  kpi_data:any

  breve:any[]=[]

  tres_breve:any[]=[]

  long:any[]=[]

  periode = 'ANNEE'


  ngOnInit(): void {
    this.netSubs = this.store.pipe(select('header_store'))?.subscribe(this.preSetAgency.bind(this));
  }

  preSetAgency(state: any) {
    this.getCoupure(state.code, state.type);
    this.statistiqueCoupure(state.code, state.type);

  }

  getCoupure(code, type) {
    this.service.getCoupureGraphique( type, code, this.periode).subscribe(
      (data) => {

        this.axis =[...new Set(data.map((valeur) => valeur['mois']))]
        for (let i = 1; i <= this.axis.length ; i++) {
          this.tres_breve.push(0);
          this.breve.push(0);
          this.long.push(0);
        }

        for (let i = 0; i < data.length; i++) {

          for (let j = 0; j <= this.axis.length ; j++) {
            if(data[i].mois == this.axis[j]){

              if( data[i].groupe == 'GR1' ){
                this.tres_breve[this.axis.indexOf(this.axis[j])] = data[i].nombre

              }else if( data[i].groupe === 'GR2'){
                this.breve[this.axis.indexOf(this.axis[j])] = data[i].nombre
              }else if(data[i].groupe === 'GR3'){
                this.long[this.axis.indexOf(this.axis[j])] = data[i].nombre
              }
            }
          }
        }
        this.typeCoupure={
          categories: this.axis,
          data:[this.tres_breve,this.breve,this.long]
        }
      },
      (error) => {
        this.loading = false;
      },
    )

  }

  statistiqueCoupure(code, type) {
    this.loading = true;
    this.service.getStatistique_coupure(type, code, this.periode).subscribe(
      (data) => {

        this.loading = false;
        this.kpi_data = data[0];

      },
      (error) => {
        this.loading = false;
      },
    );
  }

  switchCoupure(arg: any){
  }



}
