import { Action } from '@ngrx/store';

export const SELECT_AGENCY = '[HEADER] AGENCY';

export class SelectAgency implements Action {
  readonly type = SELECT_AGENCY;

  constructor(public payload: { code: any; type: any }) {}
}

export type Actions = SelectAgency;
