import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import * as HeaderActions  from "../../actions/header.actions";
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-selecter',
  templateUrl: './selecter.component.html',
  styleUrls: ['./selecter.component.scss']
})
export class SelecterComponent implements OnInit{

    storeSubs: Subscription;
    listAgencies: any;
    agencies = [];
    all = {
        nom: '',
        code: '',
        agence: {
            nom: 'Tous',
            code: 'global',
        }
    };

    constructor(private service: MainScreenService, private store: Store<any>, private router: Router) {
        this.storeSubs = store.select('header_store')?.subscribe(this.preSetAgency.bind(this));
    }

    ngOnInit(): void {
        this.getAgencies();

    }

    getUrl(): string{
        if(this.router.url.includes('geographique'))
        {
            return 'geographique'
        }
        return 'graphique';
    }

    preSetAgency(state: any) {
        this.selectedAgency = state.code;
    }

    setAgency() {
        let type: string;
        switch(this.selectedAgency.length ){
            case 2 :
                type = 'agence'
                break;
            case 4 :
                type = 'secteur'
                break;
            default:
                type = 'global';
                break;
        }
        this.store.dispatch(new HeaderActions.SelectAgency({code: this.selectedAgency, type: type}));
    }

    groupingHelper(item){
        return item.agence.nom}

    selectedAgency : any = 'global';


    onSelectClient() {
        this.setAgency();
    }

    compareAgencesFn = (item, selected) => {
        if (selected.nom && item.nom) {
            return item.nom === selected.nom;
        }
        return false;
    }

    groupValueFn: (__: string, children: any[]) => any= (__: string, children: any[]) => {
        const v = children[0].agence;
        return v;
    }


    ngSearchFn = (code: string, item: any) => {
        return item?.nom.toLowerCase().indexOf(code.toLowerCase()) > -1 || item.agence?.nom.toLowerCase().indexOf(code.toLowerCase()) > -1;
    }

    getAgencies(){
        this.listAgencies = [];
        this.service.getAgencies().subscribe(
            (data : any) => {
                data.forEach((element) => {
                    if(element.agence.code_agence !== 'global'){
                        this.listAgencies.push(
                            {
                                nom: element.nom_secteur,
                                code: element.code_secteur,
                                agence: {
                                    code: element.agence.code_agence,
                                    nom: `${element.agence.nom_agence} ${element.agence.code_agence}`
                                }
                            }
                        );

                        this.agencies.push({
                            code: element.agence.code_agence,
                            nom: `${element.agence.nom_agence} ${element.agence.code_agence}`
                        });
                    }

                });

                this.agencies =  this.agencies.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.code === value.code
                        ))
                )

                this.listAgencies.sort(function (a, b) {
                    if (a.nom < b.nom) {
                        return -1;
                    }
                    if (a.nom < b.nom) {
                        return 1;
                    }
                    return 0;
                });
                this.listAgencies.sort(function (a, b) {
                    if (a.agence.nom < b.agence.nom) {
                        return -1;
                    }
                    if (a.agence.nom > b.agence.nom) {
                        return 1;
                    }
                    return 0;
                });
                this.agencies.sort(function (a, b) {
                    if (a.nom < b.nom) {
                        return -1;
                    }
                    if (a.nom > b.nom) {
                        return 1;
                    }
                    return 0;
                });

                this.listAgencies = [this.all].concat(this.listAgencies);
                this.agencies = [{code: 'global', nom: 'Tous'}].concat(this.agencies);

            }
        );
    }

    hideToggleButton() {
        const elem = document.getElementById("check");
        elem.hidden = true;
    }

    displayToggleButton() {
        const elem = document.getElementById("check");
        elem.hidden = false;
    }
}
