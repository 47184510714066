import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import {HISTO_PAIR_Percent} from "../../../../../utils/charts/area-chart-option";
import * as _ from 'lodash';

@Component({
  selector: 'app-details-postes',
  templateUrl: './details-postes.component.html',
  styleUrls: ['./details-postes.component.css']
})
export class DetailsPostesComponent {

  highcharts = Highcharts;
  chartOptions =  _.cloneDeep(HISTO_PAIR_Percent);
  _data;
  chartUpdateFlag = false;

  @Input() set data(value){
   this.chartOptions.series[0].data = [];
    this.chartOptions.series[1].data = [];
    this.chartOptions.xAxis.categories = [];
    this.chartOptions.series[0].name = 'Taux de charges maximum';
    this.chartOptions.series[1].name = 'Taux de charges moyen';
    this.chartOptions.yAxis.title = null;

    if(value.jour){
      this._data = value;
      this.chartOptions.xAxis.categories = JSON.parse(this._data.jour);
      this.chartOptions.series[0].data = JSON.parse(this._data.taux_charge_max);
      this.chartOptions.series[1].data = JSON.parse(this._data.taux_charge_moyen);
    }

    this.chartUpdateFlag = true;
  }

  get data() {
    return this._data;
  }
}
