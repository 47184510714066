import {Component, OnInit} from '@angular/core';
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-qualite-tensions',
  templateUrl: './qualite-tensions.component.html',
  styleUrls: ['./qualite-tensions.component.scss']
})
export class QualiteTensionsComponent implements OnInit {
  date: any;
  legendTension: any;
  legendPoste: any;

  constructor(private service: MainScreenService, private store: Store<any>, private datePipe :DatePipe) {}

  loading = false;

  compteurs : any;

  type = 'tension';

  periode = 'jour';

  meters;

  postes;

  tempSubscription!: Subscription;

  perimeterType = 'global';
  first = true;
  perimeterCode ='global';
  dateDebut;
  dateFin;
  dateOfData = '';

  subscription!: Subscription;

  netSubs!: Subscription;

  isPoste = false;
  isMeter = false;


  ngOnInit(): void {
    this.tempSubscription = this.store
        ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
    this.netSubs = this.store.pipe(select('header_store'))?.subscribe(this.preSetAgency.bind(this));
  }


  preSetAgency(state: any) {
    this.perimeterType = state.type;
    this.perimeterCode = state.code;
    this.getTensionData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin);
    this.getPosteData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin)
    this.first = false;
  }

  getDateTension(){
    if(this.periode === 'intervalle'){
      this.dateOfData = `Date: ${this.datePipe.transform(new Date(this.dateDebut), "dd/MM/yy")} au ${this.datePipe.transform(new Date(this.dateFin), "dd/MM/yy")}`
    }
    else {
      this.dateOfData = `Date: ${this.datePipe.transform(new Date(this.date), "dd/MM/yy")}`

    }
  }

  preSetDate(state: any) {
    this.date= state.selected_date;
    this.dateDebut = state.start_date;
    this.dateFin = state.end_date;
    this.periode = state.period;
    this.getDateTension();
    if(!this.first){
      this.getTensionData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin);
      this.getPosteData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin)
    }
  }

  getTensionData(code, type, date, date_debut, date_fin) {
    this.loading = true;
    this.meters = [];
    this.service.getTension(type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
          this.loading = false;
          this.meters = data.coordonnees;
          this.legendTension = data.legend;
          this.isMeter = true;
        },
        (error) => {
          this.loading = false;
        },
    );
  }

  getPosteData(code, type, date, date_debut, date_fin) {
    this.loading = true;
    this.postes = [];
    this.service.getPostes(type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
          this.loading = false;
          this.postes = data.coordonnees;
          this.legendPoste = data.legend;
          this.isPoste = true;
        },
        (error) => {
          this.loading = false;
        },
    );
  }

}
