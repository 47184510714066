<nb-card>
    <nb-card-body>
        <div class="date">
            <div class="date-container">
                <div [ngClass]="periode === 'jour' ? 'label text-underlined' : 'label'" (click)="pickDate('jour')">
                    Jour
                    <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
                </div>
                <div [ngClass]="periode === 'mois' && clickable ? 'label text-underlined' : !clickable ? 'label-diseable' : 'label'"(click)="pickDate('mois')">
                    Mois
                    <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
                </div>
                <div [ngClass]="periode === '30_derniers_jours' && clickable ? 'label text-underlined' : !clickable ? 'label-diseable' : 'label'"(click)="pickLastDays('30_derniers_jours')">
                    30j
                    <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
                </div>
                <div [ngClass]="periode === 'intervalle' && clickable ? 'label text-underlined' : !clickable ? 'label-diseable' : 'label'" (click)="pickDate('intervalle')">
                    Intervalle
                    <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
                </div>
            </div>
            <div *ngIf="date" class="title">
                {{date}}
            </div>
        </div>

        <div>
        <input  id ="jour" nbInput [nbDatepicker]="dateTimePicker">
        <nb-datepicker [max]="maxDate"  [(ngModel)]="selectedDate" (dateChange)="onDateChange($event)" #dateTimePicker></nb-datepicker>
            <input  id ="mois" nbInput [nbDatepicker]="dateTimePickerMonth">
            <nb-datepicker [max]="maxDate"  [filter]="filterFn" startView="month"  [(ngModel)]="selectedDate" (dateChange)="onDateChange($event)" #dateTimePickerMonth></nb-datepicker>
        <input id="intervalle" nbInput  [nbDatepicker]="formpicker">
        <nb-rangepicker (rangeChange)="onRangeChange($event)" #formpicker></nb-rangepicker>
        </div>
    </nb-card-body>
</nb-card>

