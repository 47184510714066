<div class="main-page">
    <!-- Premier écran -->
    <div class="card-conso-title">Consommation</div>
    <div class="card-screen-with-tilte">
        <ngx-loading
                [show]="loading"
        ></ngx-loading>
        <div class="card-screen-conso">
            <div class="title">Moyenne par agence {{typeConso}} {{periodeTitle}}</div>
            <input id="check" class="switch" type="checkbox" [checked]="typeConso === 'kWh' ? true : false"
                   (click)="changeType()">
            <app-histogrammevertical *ngIf="consoParAgenceKWh && typeConso === 'kWh'"
                                     [data]="consoParAgenceKWh"></app-histogrammevertical>
            <app-histogrammevertical *ngIf="consoParAgenceMRU && typeConso === 'MRU'"
                                     [data]="consoParAgenceMRU"></app-histogrammevertical>
        </div>
        <div class="card-screen-conso">
            <div class="card-screen-vertical-conso">
                <div class="title">Totale par tarif {{periodeTitle}}</div>
                <app-donut-chart *ngIf="consoParTarif"
                                 [data]="consoParTarif"
                                 [colors]="colors">

                </app-donut-chart>
            </div>
            <div class="card-screen-vertical-conso">
                <div class="title">Totale activité {{periodeTitle}}</div>
                <app-donut-chart *ngIf="consoParActivite"
                                 [data]="consoParActivite"
                                 [colors]="colorsTarif">
                </app-donut-chart>
            </div>
        </div>
        <div class="card-screen-conso">
            <div class="card-screen-row">
                <div class="card-screen-three-part">
                    <div class="conso-title">
                        <div class="title-medium-size">Conso totale</div>
                        <div class="title-medium-size">Coût Conso totale</div>
                    </div>
                    <div class="conso-title">
                        <span *ngIf="sommeConsoGwh " class="title-big-size"> {{sommeConsoGwh | number:'1.2-2'}}<span class="espace"> GWH </span></span>
                        <span *ngIf="sommeConsoMru" class="title-big-size"> {{sommeConsoMru | number:'1.2-2'}}<span class="espace"> MRU </span></span>
                        <span *ngIf="!sommeConsoGwh " class="title-big-size"> -- <span class="espace"> GWH </span></span>
                        <span *ngIf="!sommeConsoMru" class="title-big-size"> -- <span class="espace"> MRU </span></span>
                    </div>
                    <app-repartition-conso-calendrier-horair *ngIf="consoFonctionCalendrie" [colors]="colorsRepartitionConso" [data]="consoFonctionCalendrie"></app-repartition-conso-calendrier-horair>
                </div>
                <div class="content-row">
                    <div class="card-screen-one-part">
                        <div class="min-conso">Moyenne globale</div>
                        <span class="medium-conso"> {{consoMoyenGWH}}<span class="espace">GWH</span></span>
                        <span class="medium-conso"> {{consoMoyenMRU}}<span class="espace"> MRU</span></span>
                    </div>
                    <div class="card-screen-one-part">
                        <div class="min-conso">Conso mois en cours</div>
                        <span *ngIf="consoMoisCoursMRU" class="medium-conso"> {{consoMoisCoursMRU}}<span class="espace">MRU </span></span>
                        <span *ngIf="!consoMoisCoursMRU" class="medium-conso"> -- <span class="espace">MRU </span></span>
                    </div>
                    <div class="card-screen-one-part">
                        <div class="min-conso">Conso veille</div>
                        <span *ngIf="consoVeilleGWH" class="medium-conso"> {{consoVeilleGWH}}<span  class="espace">MRU</span></span>
                        <span *ngIf="!consoVeilleGWH" class="medium-conso"> -- <span  class="espace">MRU</span></span>
                    </div>
                </div>

            </div>


        </div>
        <div class="card-screen-conso">
            <div class="title">Totale {{exceptionalTitle}}</div>
            <app-histogrammevertical-courbe *ngIf="consoTotaleMois"
                                            [data]="consoTotaleMois"></app-histogrammevertical-courbe>
        </div>
    </div>
    <!--here -->
    <!-- Deuxième écran -->
    <div class="card-screen-with-tilte">
        <ngx-loading
                [show]="loading"
        ></ngx-loading>
        <div class="card-screen">
            <div class="title">Transactions {{exceptionalTitle}}</div>
            <app-transaction-par-mois *ngIf="transactionParMois" [data]="transactionParMois"></app-transaction-par-mois>
        </div>
        <div class="card-screen">
            <div class="title">Achats par agence Somelec {{periodeTitle}}</div>
            <app-histogramme-pair
                    *ngIf="venteParAgence"
                    [data]="{data: venteParAgence , type: 'venteParAgence'}">
            </app-histogramme-pair>
        </div>
        <div class="card-screen">
            <div class="title">Ventes {{exceptionalTitle}}</div>
            <app-histogramme-pair
                    *ngIf="venteParMois.length > 0"
                    [data]="{ type: 'venteParMois', data: venteParMois }"
            ></app-histogramme-pair>
        </div>
        <div class="card-screen">
            <div class="card-screen-vertical">
                <div class="title">Ventes par agence (MRU) {{periodeTitle}}</div>
                <div class="title-2">Agence-Revendeur-Wallet</div>
                <app-donut-chart
                        [colors]="colorsRepartition"
                        [data]="repartitionParAgence"
                ></app-donut-chart>
            </div>
            <div class="card-screen-vertical">
                <div class="title">Transactions par agence {{periodeTitle}}</div>
                <div class="title-2">Agence-Revendeur-Wallet</div>
                <app-donut-chart
                        [colors]="colorsTransaction"
                        [data]="transactionParAgence"
                ></app-donut-chart>
            </div>
        </div>
    </div>
    <!-- Troisième écran -->
    <div class="card-screen">
        <ngx-loading
                [show]="loading"
        ></ngx-loading>
        <div class="card-screen">
            <app-courbe-unique
                *ngIf="dettes.length > 0" [data]="dettes">
            </app-courbe-unique>
        </div>
        <div class="card-screen">
            <div class="title">Conso par agence {{periodeTitle}}</div>
            <app-conso-par-agence *ngIf="conso" [data]="conso"></app-conso-par-agence>
        </div>
        <div class="card-screen">
            <app-repartition-vente [data]="dettesToDisplay" *ngIf="dettesToDisplay"></app-repartition-vente>
        </div>
        <div class="card-screen ">
            <div class="card-screen no-border" *ngIf="energieVendue && energieVendue.length > 0">
                <div class="title-bloc">Energie vendue</div>
                <div class="client">
                    <span class="title-text" *ngIf="energieNonDistribuee.length > 0">
                        {{energieVendue[1]}}
                    </span>
                    <img src="assets/playground_assets/power.png"
                         alt="power13282"
                    />
                </div>
                <div class="client">
                    <span class="title-text" *ngIf="energieNonDistribuee.length > 0">
                       {{energieVendue[0]}}
                    </span>
                    <img src="assets/playground_assets/money.png"
                         alt="power13282"
                    />
                </div>
                <div class="client">
                    <span class="title-text">
                       {{energieVendue[2]}}
                    </span>
                    <img src="assets/playground_assets/transaction.png"
                         alt="power13282"
                    />
                </div>
            </div>
            <div class="card-screen no-border">
                    <div class="title-bloc">Energie vendue non consommée</div>
                    <div class="client">
                    <span class="title-text">
                        {{energieNonDistribuee[0]}}
                    </span>
                        <img src="assets/playground_assets/power.png"
                             alt="power13282"
                        />
                    </div>
                    <div class="client">
                    <span class="title-text">
                         {{energieNonDistribuee[1]}}
                    </span>
                        <img src="assets/playground_assets/money.png"
                             alt="power13282"
                        />
                    </div>
            </div>
            <div class="card-screen no-border" *ngIf="repartitionVente">
                <div class="title-bloc">Répartition des ventes</div>
                <div class="client">
                    <span class="title-text">
                        {{repartitionVente[0]}}
                    </span>
                    <img src="assets/playground_assets/organization.png"
                         alt="power13282"
                    />
                </div>
                <div class="client">
                    <span class="title-text">
                        {{repartitionVente[1]}}
                    </span>
                    <img src="assets/playground_assets/partner.png"
                         alt="power13282"
                    />
                </div>
                <div class="client">
                    <span class="title-text">
                        {{repartitionVente[2]}}
                    </span>
                    <img src="assets/playground_assets/digital-wallet.png"
                         alt="power13282"
                    />
                </div>
            </div>
            <div class="card-screen no-border">
                <div class="title-bloc">Top 3 des agences (Agence-Revendeur-Wallet)</div>
                <app-histogramme-horizontale *ngIf="topTroisVendeurs" [salesData]="topTroisVendeurs"></app-histogramme-horizontale>
            </div>
        </div>
    </div>
    <!-- Quatrième écran -->
    <div class="card-screen">
        <app-consommation [display]="false" class="carte"></app-consommation>
    </div>
</div>

