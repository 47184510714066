import  * as SelectAgency from "../actions/header.actions";

const initialState = {
  code: 'global',
  type: 'global'
};

export function SelectAgencyReducer(state = initialState, action: SelectAgency.Actions) {
  if (action.type === SelectAgency.SELECT_AGENCY) {
    return {
      ...state,
      code: action.payload.code,
      type: action.payload.type
    };
  }
  return state;
}
