<div  class="select">
    <ng-select *ngIf="listAgencies.length > 0 && getUrl() === 'geographique'" [items]="listAgencies"
               bindLabel="nom"
               bindValue="code"
               [groupBy]="groupingHelper"
               [clearable]="false"
               [selectableGroup]="true"
               [searchable]="true"
               [searchFn]="ngSearchFn"
               [groupValue]="groupValueFn"
               [compareWith]="compareAgencesFn"
               notFoundText="Aucun élément trouvé"
               (change)="onSelectClient()"
               [(ngModel)]="selectedAgency">
    </ng-select>
    <ng-select *ngIf="agencies.length > 0 && getUrl() === 'graphique'" [items]="agencies"
               bindLabel="nom"
               bindValue="code"
               [clearable]="false"
               [searchable]="true"
               (open)="hideToggleButton()"
               (close)="displayToggleButton()"
               [searchFn]="ngSearchFn"
               notFoundText="Aucun élément trouvé"
               (change)="onSelectClient()"
               [(ngModel)]="selectedAgency">
    </ng-select>
</div>
