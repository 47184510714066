export const COURBE_MOYENNE = {
    chart: {
        backgroundColor: 'transparent',
    },
    colors: ['#F5620C'],
    title: {
        text: '',
        style: {color: 'transparent',
        }

    },
    legend: {
        enabled: true,
        itemStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '0.8vw',
        },
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        lineWidth: 2,
        categories: [],
        labels: {
            style: {
                color: 'white',
                fontSize:  '0.8vw'
            }
        },
    },
    yAxis:
        {
            gridLineWidth: 0,
            title: '',
            min: 0,
            lineWidth: 2,
            labels: {
                style: {
                    fontSize:  'O.8vw',
                    color: 'white',
                }
            },
            plotLines: [
                {
                    color: '#70FF00',
                    width: 2,
                    dashStyle: 'longdash',
                    value: 50,
                    zIndex: 5,
                    label: {
                        text: '50 Hz',
                        align: 'right',
                        style: {
                            color: '#70FF00',
                            fontSize:  '0.8vw',
                            fontWeight: 'bold'
                        }
                    },
                },
            ]
        },

    series: [
        {
            lineWidth: 3,
            fillOpacity: 0.3,
            marker: {
                enabled: false,
            },
            color: '#84E6F9',
            type: 'areaspline',
            name: 'Moyenne fréquence',
            data: [46, 67, 24, 58, 30, 40]
        },
    ]
}
