import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tableaux',
  templateUrl: './tableaux.component.html',
  styleUrls: ['./tableaux.component.css']
})
export class TableauxComponent {

  _data;

  @Input() set data(value){
    if(value){
      this._data = value;
    }
  }

  get data(){
    return this._data;
  }



}
