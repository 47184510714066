import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    uipServer = environment.uipServer + '/api/v1/';



    constructor(private http: HttpClient, private errorHandler: ErrorHandlerService) {}

    login(
        login: string,
        password: string,
    ): Observable<any> {
        return this.http
            .post<any>(this.uipServer + 'auth/login', {
                username: login,
                password,
            })
            .pipe(
                map((response: any) => {
                    const userToken: any= response;
                    localStorage.setItem('userToken', JSON.stringify(userToken));
                    return userToken;
                })
            );
    }

    logout() {
        localStorage.removeItem("userToken");
    }


}
