export const COURBE_UNIQUE_DATA = {
    chart: {
        backgroundColor: 'transparent',
    },
    colors: ['#47C471'],
    title: {
        text: '',
        style: {color: 'transparent',
        }

    },
    credits: {
        enabled: false,
    },

    legend: {
        enabled: true,
        itemStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '0.8vw',
        },
        symbolWidth: 20
    },
    tooltip: {
        enabled: true
    },

    xAxis: [{
        lineWidth: 1,
        categories: [],
        labels: {
            style: {
                color: '#FFF',
                fontSize:  '0.8rem'
            }
        },
    }],

    yAxis: [
        {
            labels: {
                style: {
                    color: '#FFF',
                    fontSize:  '0.8rem'
                }
            },
            title: {
                text: 'Recouvrement',
                style: {
                    color: '#FFF',
                    fontSize: '0.5vw'
                }
            },
            gridLineWidth: 0,
            lineWidth: 1,
        },
        {
            title: {
                text: 'Remboursement',
                style: {
                    color: '#FFF',
                    fontSize: '0.5vw'
                }
            },
            labels: {
                style: {
                    color: '#FFF',
                    fontSize:  '0.8rem'
                }
            },
            gridLineWidth: 0,
            lineWidth: 1,
            opposite: true,

        }
    ],

    series: [
    {
        name: 'Recouvrement (MRU)',
        type: 'spline',
        yAxis: 0,
        lineWidth: 2,
        data: [],
        marker: {
            fillColor: '#47C471',
            lineWidth: 1,
            lineColor: '#47C471',
            radius: 1
        }
    },
    {
        name: 'Remboursement (MRU)',
        type: 'spline',
        yAxis: 1,
        data: [],
        lineWidth: 2,
        color: '#8F77FA',
        marker: {
            fillColor: '#8F77FA',
            lineWidth: 2,
            lineColor: '#8F77FA',
            radius: 1
        }
    }]
};
