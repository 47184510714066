import {Component, Input} from '@angular/core';
import {Highcharts} from "highcharts/modules/map";
import {formatTime} from "../../../../utils/functions";

@Component({
  selector: 'app-details-events',
  templateUrl: './details-events.component.html',
  styleUrls: ['./details-events.component.css']
})
export class DetailsEventsComponent {

    Highcharts = Highcharts;

    _data;

    rowData;



    colors = ['#01ABAE', 'rgba(189, 87, 65, 1)', '#47C471', '#D0C000', '#60BA9C', '#DEDFDE', 'rgba(103, 189, 140, 0.21)'];


    chartData;
    chartUpdateFlag: boolean;


    @Input() set data(value){
        this._data = value;
        this.rowData = this._data.array_details.map(objet => {
            return { ...objet, duree: formatTime(Number(objet.dure)) };
        });
        this.chartData =  {data: this._data.array_pourcent, type: 'events'};
        this.chartUpdateFlag = true;
    }

    get data() {
        return this._data;
    }
}
