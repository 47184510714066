import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainScreenService {

  uipServer = environment.uipServer;
  
  constructor(private _http: HttpClient, private _errorHandler: ErrorHandlerService) {}

  getDataMureImage(): Observable<any> {
    return this._http
      .get(`${environment.uipServer}/api/v1/murimage/list`)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getConso(type: string, perimetre_type: string, perimetre_value: string, period: string, legend_code: string, date: string, date_debut: string, date_fin: string): Observable<any> {
    let params;
    if(period === 'intervalle'){
      params = `&start_date=${date_debut}&end_date=${date_fin}`;
    }else {
      params = `&date_search=${date}`;
    }
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/type/${type}?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}&legend_code=${legend_code}${params}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getTension(perimetre_type: string, perimetre_value: string, period: string, date: string, date_debut: string, date_fin: string): Observable<any> {
    let params;
    if(period === 'intervalle'){
      params = `&start_date=${date_debut}&end_date=${date_fin}`;
    }else {
      params = `&date_search=${date}`;
    }
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/type/tension?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}${params}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getPostes(perimetre_type: string, perimetre_value: string, period: string, date: string, date_debut: string, date_fin: string): Observable<any> {
    let params;
    if(period === 'intervalle'){
      params = `&start_date=${date_debut}&end_date=${date_fin}`;
    }else {
      params = `&date_search=${date}`;
    }
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/type/poste?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}${params}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getAbonnements(perimetre_type: string, perimetre_value: string, period: string): Observable<any> {
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/type/client?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getCoupure(perimetre_type: string, perimetre_value: string, period: string, date: string, date_debut: string, date_fin: string): Observable<any> {
    let paramsCoupure;
    if(period === 'intervalle'){
      paramsCoupure = `&start_date=${date_debut}&end_date=${date_fin}`;
    }else {
      paramsCoupure = `&date_search=${date}`;
    }
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/type/coupure?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}${paramsCoupure}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getAgencies(): Observable<any> {
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/secteur-agency`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getDetailsCoupure(reference: string, end_date: string): Observable<any> {
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/coupures?reference=${reference}&end_date=${end_date}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getDetailsTension(reference: any, end_date: string): Observable<any> {
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/tension?num_dossier=${reference}&end_date=${end_date}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getDetailsPoste(poste: any, date: string): Observable<any> {
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/details-poste?id_poste=${poste}&date_search=${date}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getConsoGraphique(type: string, perimetre_type: string, perimetre_value: string, period: string, date: string, date_debut: string, date_fin: string): Observable<any> {
    let params;
    if(perimetre_type === 'global'){
      params = `type/${type}?`;
    } else {
      params = `type/${type}?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&`;
    }
    if(period === 'intervalle'){
      params += `period=${period}&start_date=${date_debut}&end_date=${date_fin}`;
    }else {
      params += `period=${period}&date_search=${date}`;
    }
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/tuile/${params}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getCoupureGraphique(perimetre_type: string ='global', perimetre_value: string = 'global', period: string = 'annee'): Observable<any> {

     let params = `?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}`;

    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/coupures-durees${params}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getDetailsConso(reference: any, end_date: string): Observable<any> {
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/detail-meter?reference=${reference}&end_date=${end_date}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getConsoAnnuelHoraire(type:string ,perimetre_type: string ='global', perimetre_value: string = 'global', period: string = 'annee', date: string, date_debut: string, date_fin: string): Observable<any> {
    let params;
      params = `type/${type}?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}`;
    if(period === 'intervalle'){
      params += `&start_date=${date_debut}&end_date=${date_fin}`;
    }else {
      params += `&date_search=${date}`;
    }
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/tuile/${params}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getEvents(perimetre_type: string, perimetre_value: string, period: string, date: string, date_debut: string, date_fin: string, type_event: string, code_color: string): Observable<any> {
    let params;
    if(period === 'intervalle'){
      params = `&start_date=${date_debut}&end_date=${date_fin}`;
    }else {
      params = `&date_search=${date}`;
    }
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/type/evenement?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}&type_event=${type_event}&code_color=${encodeURIComponent(code_color)}${params}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getEventDetails(reference: any, end_date: string, type: string): Observable<any> {
    return this._http
        .get(`${environment.uipServer}/api/v1/murimage/sig/evenement?num_dossier=${reference}&end_date=${end_date}&type_event=${type}`)
        .pipe(catchError(this._errorHandler.handleError));
  }

  getStatistique_coupure(perimetre_type: string ='global', perimetre_value: string = 'global', period: string = 'annee'): Observable<any> {
    let params;
    params = `?perimeter_type=${perimetre_type}&perimeter_value=${perimetre_value}&period=${period}`;
    return this._http
      .get(`${environment.uipServer}/api/v1/murimage/sig/statistique-coupure${params}`)
      .pipe(catchError(this._errorHandler.handleError));
  }
}
