import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-details-compteur-dialog',
  templateUrl: './details-compteur-dialog.component.html',
  styleUrls: ['./details-compteur-dialog.component.scss']
})
export class DetailsCompteurDialogComponent implements OnInit{

  type;

  compteur;

  loading = false;

  info_client;

  isPoste;


  ngOnInit(): void {
  }

}
