import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import {NbToastrService} from "@nebular/theme";
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;

  constructor(
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authService: AuthService,
      private notificationService: NbToastrService,
  ) {
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group(this.loginFormFields);
    this.authService.logout();
    this.returnUrl = this.route?.snapshot?.queryParams?.returnUrl || '/home';
  }

  permissions = [];

  loginFormFields = {
    email: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    stayConnected: false,
  };

  isLoading = false;

  returnUrl: string;

  get email() {
    return this.loginForm?.get('email');
  }

  get password() {
    return this.loginForm?.get('password');
  }


  onSubmit() {
    this.isLoading = true;
    const {email, password} = this.loginForm?.value;
    this.authService.login(email, password).subscribe(
        (userInfo) => {
          this.permissions = Object.keys(userInfo?.user?.permissions);
          this.isLoading = false;
            this.notificationService.success(
                'Connexion avec succès',
                'Succès',
            );
            this.router.navigateByUrl(this.returnUrl);
        },
        () => {
          this.isLoading = false;
          this.notificationService.danger(
              'Identifiant ou mot de passe incorrect',
              'Echec',
          );
        },
    );
  }

}
