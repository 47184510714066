<div class="main-page">
    <!-- Premier écran -->
    <div class="card-screen">
        <ngx-loading
                [show]="loading"
        ></ngx-loading>
        <div class="card-screen">

                <div class="title"> Statistique</div>


            <div class="stat">

                <div class="caidi"> <app-get-statistique *ngIf="kpi_data" [salesData]="kpi_data"></app-get-statistique></div>
            </div>
        </div>

        <div class="card-screen">
            <div class="title"> Fréquence moyenne (Hz)</div>
            <app-courbe-moyenne>

            </app-courbe-moyenne>
        </div>
        <div class="card-screen">
            <div class="title"> Durée moyenne des coupures</div>
            <app-histogrammevertical *ngIf="dureeMoyenne"
                                     [data]="dureeMoyenne"></app-histogrammevertical>
        </div>
    </div>
    <!-- Deuxième écran -->
    <div class="card-screen">
        <div class="card-screen">
            <div class="title"> Fréquence coupure longue, brève, très brève</div>

            <app-histogramme-triphase
              *ngIf="typeCoupure"
              [data]="typeCoupure"
            ></app-histogramme-triphase>
        </div>
       <div class="card-screen">
            <div class="title">Surtension</div>
            <app-surtension-baisse-tension></app-surtension-baisse-tension>
        </div>
    </div>
    <!-- Troisième écran -->
    <div class="card-screen">
       <div class="card-screen">

        </div>
    </div>
</div>
