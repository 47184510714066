import {
    DetailsCompteurDialogComponent
} from "../app/pages/home/components/details-compteur-dialog/details-compteur-dialog.component";

export function   displayLegend(){
    let coll = document.getElementsByClassName("collapsible");
    let i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content?.style?.maxHeight){
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }
}
export function createCustomPopup(componentFactoryResolver: any, injector: any, compteurs: any, type: any, data: any, isPoste: boolean = false) {
    const factory = componentFactoryResolver.resolveComponentFactory(DetailsCompteurDialogComponent);
    const component = factory.create(injector);
    component.instance.compteur = data;
    component.instance.type = type;
    component.instance.isPoste = isPoste;

    //Manually invoke change detection, automatic wont work, but this is Ok if the component doesn't change
    component.changeDetectorRef.detectChanges();

    return component.location.nativeElement;
}
