<ngx-loading
        [show]="loading"
></ngx-loading>
<app-carte *ngIf="compteurs && dateOfData"
           [compteurs]="compteurs"
           [allMeters]="compteurs"
           [titre]="' - Evènements'"
           [isEvent]="true"
           [dateOfData]="dateOfData"
           (filter)="filter($event)"
></app-carte>
