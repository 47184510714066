<table class="table table-bordered table-dark">
    <tr class="left">
        <th scope="col">Dettes</th>
        <th scope="col">Dettes</th>
        <th scope="col">Dettes</th>
    </tr>
    <tbody>
    <tr >
        <td class="no-data"></td>
        <td>Solde global dette</td>
        <td>fff</td>
    </tr>
    <tr >
        <td class="no-data"></td>
        <td>Montant Recouvré</td>
        <td>--</td>
    </tr>
    <tr class="left">
        <th scope="col">Avoir</th>
        <th class="no-data" >Pas de données</th>
        <th class="no-data">Pas de données</th>
    </tr>
    <tr >
        <td>Montant Remboursé</td>
        <td>Montant Remboursé</td>
        <td>--</td>
    </tr>
    </tbody>
</table>
