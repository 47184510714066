import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import {HISTOGRAMME_OPTIONS} from "../../../../../utils/charts/column-chart-options";
import * as _ from 'lodash';

@Component({
  selector: 'app-conso-details',
  templateUrl: './conso-details.component.html',
  styleUrls: ['./conso-details.component.css']
})
export class ConsoDetailsComponent {

    highcharts = Highcharts;
    chartOptions =  _.cloneDeep(HISTOGRAMME_OPTIONS);
    _data;
    chartUpdateFlag = false;

    @Input() set data(value){
        this.chartOptions.series[0].data = [];
        this.chartOptions.xAxis.categories = [];
        this._data = value;
        this.chartOptions.series[0].name = 'Consommation kWh';
        this.chartOptions.plotOptions.series.borderColor = '#47C471';
            this.chartOptions.colors = ['#47C471'];
            this.chartOptions.xAxis.categories = value.jour_conso;
            this._data.consos.forEach((element) => {
                this.chartOptions.series[0].data.push(Number(element));
            });

        this.chartUpdateFlag = true;
    }

    get data() {
        return this._data;
    }
}
