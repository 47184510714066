import {Component, ComponentFactoryResolver, Injector, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {NbToastrService} from "@nebular/theme";
import {Store} from "@ngrx/store";
import * as L from "leaflet";
import {createCustomPopup} from "../../../../../utils/marker";
import {configNotification} from "../../../../../utils/variables";
import {createCustomIcon} from "../../../../../utils/function";

@Component({
  selector: 'app-carte-tension',
  templateUrl: './carte-tension.component.html',
  styleUrls: ['./carte-tension.component.css']
})
export class CarteTensionComponent {
  temporal!: Subscription;
  date;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector, private service: MainScreenService,
              private toastrService: NbToastrService,
              private store: Store<any>) {
    this.temporal = this.store
        ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
  }

  icons: any;

  channel: any;

  _compteurs: any;

  _allMeters;

  meters: any;

  postes: any;

  type;

  point;

  @Input() set allMeters(value) {
    this._allMeters = value;
  }

  @Input() titre = '';

  @Input() dateOfData = '';

  data;

  mapPostes: L.Map;

  layerGrp: L.LayerGroup;

  baseMarkerPostes: any = [];

  baseMarkerMeters: any = [];

  markerCluster: any = [];

  markerCluster2: any = [];

  isPoste = false;

  first = true;


  @Input() set compteurs(value : any){
    this.layerGrp?.clearLayers();
    this.initialize();
    if(value && this.mapPostes){
      this._compteurs = value;
      this.postes = this._compteurs.postes;
      this.meters = this._compteurs.meters;
      if(this.first){
        this.legend = this._compteurs.legendTension?.concat(this._compteurs.legendPoste);
        this.type = this._compteurs.type;
        this.displayLegend = value.displayLegend;
      }
      this.first = false;
      this.addMarkers();
    }
  }

  get compteurs(){
    return this._compteurs;
  }

  preSetDate(state: any) {
    if(state.period !== 'intervalle'){
      this.date =   this.date = state.selected_date;
    } else {
      this.date =  state.end_date;
    }
  }

  get allMeters(){
    return this._allMeters;
  }

  loading = false;

  legend: any;

  displayLegend;


  /* Filtre la carte au clique sur la légende */
  refresh($events){
    this.layerGrp?.clearLayers();
    if($events.length === 0)
    {
      this.compteurs = {postes: this.allMeters.postes, meters: this.allMeters.meters, type: this.type, displayLegend: this.displayLegend};
    }else {
      let meters = this.allMeters.meters;
      let filteredMeters = [];
      let listMetersToFiltered = [];
      $events.forEach((ele) => {
        filteredMeters = meters.filter((element) => element[2] === ele?.couleur);
        listMetersToFiltered =  listMetersToFiltered?.concat(filteredMeters);
      });
      this.compteurs = {postes: this.allMeters.postes, meters: listMetersToFiltered, type: this.type, displayLegend: this.displayLegend};
    }
  }

  /* Initialise la carte tension */
  initialize() {
    if (this.mapPostes != undefined) { this.mapPostes.remove(); }
      this.mapPostes = L.map('map1').setView([18.1, -15.95], 12, );
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxNativeZoom: 18,
        maxZoom: 18,
        minZoom: 10,
        attribution: '<a href="https://www.akilee-by-ines.com">Akilee</a>',
      }).addTo(this.mapPostes);
      this.mapPostes.removeControl(this.mapPostes.zoomControl);
      this.layerGrp = L.layerGroup().addTo(this.mapPostes);
  }

  addMarkers() {
    this.getPostesMarker(this.postes, this.type);
    this.getMetersMarker(this.meters, this.type);
  }

  getPosteDetails(i: number, selected :any) {
    this.loading = true;
    this.isPoste = true;
    this.data = [];
    this.service.getDetailsPoste(selected[0], this.date).subscribe(
        (data) => {
          if(data.length > 0) {
            this.data= data[0];
          }
          this.loading = false;
          this.markerCluster2[i]?.bindPopup(() => createCustomPopup(this.componentFactoryResolver, this.injector , selected, this.type, this.data, this.isPoste), {});
          this.markerCluster2[i]?.openPopup();
        },
        (error) => {
          this.toastrService.danger(
              `Details : Données non recupérées`,
              'Une erreur est survenue.',
              configNotification
          );
          this.loading = false;
        },
    );
  }

  getTensionsData(i: number, selected :any){
    this.loading = true;
    this.isPoste = false;
    this.service.getDetailsTension(selected[3], this.date).subscribe(
        (data) => {
          this.data= data;
          this.loading = false;
          this.markerCluster[i]?.bindPopup(() => createCustomPopup(this.componentFactoryResolver, this.injector , selected, this.type, this.data, this.isPoste), {});
          this.markerCluster[i]?.openPopup();
        },
        (error) => {
          this.toastrService.danger(
              `Details : Données non recupérées`,
              'Une erreur est survenue.',
              configNotification
          );
          this.loading = false;
        },
    );
  }

  getPostesMarker(compteurs: any, type: any) {
    this.baseMarkerPostes = [];
    this.markerCluster2 = [];
    const elem = document.getElementById("legend");
    for (let i = 0; i < compteurs.length; i += 1) {
      const latitude = Number(compteurs[i][1]);
      const longitude = Number(compteurs[i][2]);
      if (latitude && longitude) {
        this.baseMarkerPostes = L.marker([latitude, longitude], {
          icon: createCustomIcon(compteurs[i][3]),
        }) .on('click', () => {
          elem.hidden = true;
          this.point = compteurs[i];
          this.getPosteDetails(i, this.point);
        }).on('popupclose', () => {
          elem.hidden = false;
        });
        this.markerCluster2.push(
            this.baseMarkerPostes
        );
        this.layerGrp?.addLayer(this.baseMarkerPostes);
      }
    }
  }



  getMetersMarker(meters: any, type: any) {
    this.baseMarkerMeters = [];
    this.markerCluster = [];
    const elem = document.getElementById("legend");
    for (let i = 0; i < meters?.length; i += 1) {
      const latitude = Number(meters[i][0]);
      const longitude = Number(meters[i][1]);
      if (latitude && longitude) {
        this.baseMarkerMeters = L.circleMarker([latitude, longitude], {
          color: meters[i][2],
          fillColor: meters[i][2],
          fillOpacity: 0.9,
          weight: 1,
          radius: 5,
        }).on('click', () => {
          if (type === "tension") {
            elem.hidden = true;
          }
          this.point = meters[i];
          if (this.displayLegend) {
            this.getTensionsData(i, this.point);
          }
        }).on('popupclose', () => {
          elem.hidden = false;
        });
        this.markerCluster.push(
            this.baseMarkerMeters
        );
        this.layerGrp?.addLayer(this.baseMarkerMeters);
      }
    }
  }

}
