import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {displayLegend} from "../../../../../utils/marker";
import {extractType} from "../../../../utils/functions";

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements  OnInit{

    lastSelected = 'transparent';

    lastSelectedType = {
        couleur: '',
        description: '',
        type: '',
        code_type: ''
    }

    @Input() titre;

    @Input() date;
    ngOnInit(): void {
        displayLegend();
    }

    visible= 0;

    eventsLegend = [];

    index = 0;

    all = {couleur: 'transparent', 'description': 'Toutes les catégories'};

    selected = [];

    first = true;

    typeEvent;

    @Output()
    refresh = new EventEmitter<any>();
  _legend : any = [];
    @Input() isEvent = false;
  @Input() set legend(legend: any){
      if(this.isEvent){
          if(this.first){
              this._legend = legend;
              let type = extractType(this._legend);
              type.forEach((element) => {
                  let  el =
                      {
                          type: element.type,
                          code: element.code,
                          data: this._legend.filter((el) => el.code_type === element.code),
                          isSelected: false,
                      };
                  this.eventsLegend.push(el);

              });
              this.eventsLegend[0].isSelected =  true;
              this.first = false;
          }
          let elem = document.getElementById(this.lastSelectedType.description);
          if(elem){
              elem.style.background = 'rgba(220,220,220, 0.1)';
          }
      } else {
          this._legend = legend;
          let elem = document.getElementById(this.lastSelected);
          if(elem){
              elem.style.background = 'rgba(220,220,220, 0.1)';
          }
      }

  }

   get legend(){
    return this._legend;
  }

  dbClick(event){
      let elem =  document.getElementById(event.couleur);
      elem.style.background = 'rgba(3, 26, 59, 1)';
      this.selected = this.selected.filter((el) => el.couleur !== event.couleur);
      this.lastSelected = 'transparent';
      this.refresh.emit(this.selected);
      if(this.selected.length === 0){
          let el =   document.getElementById(this.lastSelected);
          if(el){
              el.style.background = 'rgba(220,220,220, 0.1)';
          }
      }
  }


    displayDetails(event: any) {
     if(event.couleur !== this.lastSelected){
         let elem =  document.getElementById(event.couleur);
         elem.style.background = 'rgba(220,220,220, 0.1)';
         this.selected.push(event);
         if(this.lastSelected === 'transparent'){
             let el =   document.getElementById(this.lastSelected);
             if(el){
                 el.style.background = 'rgba(3, 26, 59, 1)'
             }
             this.lastSelected = event.couleur;
         }
         if(event.couleur === 'transparent'){
             this.selected.forEach((element) => {
                 if(element.couleur !== 'transparent') {
                     let el =   document.getElementById(element.couleur);
                     if(el){
                         el.style.background = 'rgba(3, 26, 59, 1)'
                     }
                 }
             });
             this.selected = [];
             this.lastSelected = 'transparent';
         }
         this.refresh.emit(this.selected);
     }
    }

    displayEventDetails(event: any, code: any, i:number) {
        if(event.description !== this.lastSelectedType.description){
            this.typeEvent = code;
            let elem =  document.getElementById(event.description);
            elem.style.background = 'rgba(220,220,220, 0.1)';
            if(this.lastSelectedType){
                let el =   document.getElementById(this.lastSelectedType.description);
                if(el){
                    el.style.background = 'rgba(3, 26, 59, 1)'
                }
                this.lastSelectedType = event;
            }
            this.eventsLegend[this.index].isSelected = false;
            this.index = i;
            this.eventsLegend[i].isSelected = true;
            this.refresh.emit({color: event.couleur, type: code});
        }
    }


}


