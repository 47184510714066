import {DatePipe} from "@angular/common";

export const TRANSACTION_PAR_MOIS = {
    chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
    },
    legend: {
        enabled: false,
        itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
            fontSize: '0.6vh',
        },
        symbolWidth: 80
    },
    title: {
        text: '',
        style: {
            color: ''
        }
    },

    credits: {
        enabled: false,
    },

    xAxis: {
        categories: [],
        lineWidth: 1,
        labels: {
            style: {
                color: 'white',
                fontSize: '0.5vw',
            },
            // formatter: function () {
            //     const pipe = new DatePipe('fr-FR');
            //     return pipe.transform(this.value, "MM-yyyy");
            // }
        },
    },
    yAxis: {
        gridLineWidth: 0,
        lineWidth: 1,
        title: "",
        labels: {
            enabled: true,
            style: {
                color: 'white',
                fontSize: '0.7vh',
            },
        },

    },
    plotOptions: {
        areaspline: {
            fillColor: '#46B5DC50',
            lineColor: '#46B5DC',
            marker: {
                fillColor: '#46B5DC'
            }
        }
    },
    series: [{
        data: [],
        color: 'white',
    },
    ]


};

export const ENERGY_CONSUMPTION = {
    chart: {
        type: 'spline',
        backgroundColor: 'transparent',
        width: 1600,
        height: 850,
    },
    legend: {
        itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
            fontSize: '35px',
        },
        symbolWidth: 80,
        padding: 20
    },
    title: {
        text: '',
        style: {
            color: '#FFFFFF'
        }
    },

    credits: {
        enabled: false,
    },

    xAxis: {
        categories: [],
        labels: {
            style: {
                color: 'grey',
                fontSize: '25px'
            }
        },
    },
    yAxis: {
        gridLineWidth: 0,
        lineWidth: 3,
        max: '',
        labels: {
            enabled: true,
            style: {
                color: 'grey',
                fontSize: '25px',
            },
        },

    },

    plotOptions: {
        series: {
            dataLabels: {
                enabled: false,
            },
        },
        spline: {
            lineWidth: 6,
            marker: {
                radius: 4,
                lineWidth: 5,
                fontSize: '60px',
            },
        },
    },
    series: [{
        data: [],
        color: 'green',
        name: 'Conso réelle',
        dataLabels: {
            style: {
                fontSize: '55px',
                color: '#FFFFFF',
            },
            enabled: false,
        },
    },
        {
            data: [],
            color: '#FFFFFF',
            name: 'Compteurs posés',
            dataLabels: {
                style: {
                    fontSize: '40px',
                    color: '#FFFFFF',
                }
            }
        }
    ]

};

export const METER_INSTALLATION = {
    chart: {
        type: 'spline',
        backgroundColor: 'transparent',
    },
    legend: {
        itemStyle: {
            fontWeight: 'bold',
            color: '#FFFFFF',
            fontSize: '50px',
        },
        symbolWidth: 80
    },
    title: {
        text: '',
        style: {
            color: 'white'
        }
    },

    credits: {
        enabled: false,
    },

    xAxis: {
        categories: [],
        labels: {
            style: {
                color: 'grey',
                fontSize: '25px'
            }
        },
    },
    yAxis: [
        {
            gridLineWidth: 0,
            lineWidth: 3,
            title: {
                enabled: false,
            },
            labels: {
                enabled: true,
                style: {
                    color: 'grey',
                    fontSize: '25px',
                },
            },
        },
    ],

    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
            },
        },
        spline: {
            lineWidth: 6,
        },
    },
    series: []


};
