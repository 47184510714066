import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements  OnInit{
  dropMenu = false;

  name;

  subscription: Subscription;
  refreshTime;

  constructor(
      private router: Router,
      ) {}



  ngOnInit(): void {
      this.getName();
    this.refreshTime = interval(3600000);
    this.subscription = this.refreshTime.subscribe(val => this.reloadCurrentPage());
    }


  displayRightMenu() {
    this.dropMenu = !this.dropMenu;
  }

  baseUrl(): string{
    if(this.router.url.includes('geographique'))
    {
      this.nameQuality = 'Qualité de service';
      return 'geographique'
    }
    this.nameQuality = 'Qualité de service'
    return 'graphique';

  }

  nameQuality: string;
  clickable = true;
  date: string;

  getName(){
    if(this.router.url.includes('consommation'))
    {
      this.clickable = true;
      return 'Consommation'
    }
    else if(this.router.url.includes('tensions')){
      this.clickable = false;
     return  'Qualité de service';
   }
    else if(this.router.url.includes('abonnement')){
     return  this.baseUrl() === 'geographique' ? 'Parc de clients' : '';
   }
    else if(this.router.url.includes('evenements')){
        this.clickable = true;
      return  'Evènements';
    }
    this.clickable = false;
    return 'Continuité de service';
  }

  reloadCurrentPage() {
    window.location.reload();
  }


}
