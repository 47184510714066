import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import {PIE_CHART_OPTIONS} from "../../../../../utils/charts/pie-chart-option";
import * as _ from 'lodash';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.css']
})
export class DonutChartComponent {

    _colors: any;
    _data: any;

    Highcharts = Highcharts;
    pieChartData: any =  _.cloneDeep(PIE_CHART_OPTIONS);
    sumTotalTarif: any;
    sumTotalActivite: any;
    sumTotalTransaction: any;
    sumTotalRepartition: any;
    chartUpdateFlag = false;
    type ='';

   @Input() set colors(value){
        this._colors = value;
        this.pieChartData.plotOptions.pie.colors = this.colors;
    }

    get colors(){
        return this._colors;
    }

    @Input() set data(value){
        this.pieChartData.series[0].data = [];
        this.pieChartData.title.text = '';
       if(value){
           switch (value.type){
               case 'tarif':
                   this.sumTotalTarif = this.getTotal(value.data, 1);
                   this.pieChartData.series[0].name = 'Conso Tarif';
                   this.pieChartData.title.text = this.sumTotalTarif ? this.sumTotalTarif.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '';
                   this.pieChartData.title.x = 40;
                   this.pieChartData.chart.marginRight = -70;
                   value.data.forEach((element) => {
                       this.pieChartData.series[0].data.push({name: element[0],y:(Number(element[1]/this.sumTotalTarif)*100)});
                   });
                   break;
               case 'activite' :
                   this.sumTotalActivite= this.getTotal(value.data, 1);
                   this.pieChartData.series[0].name = 'Conso Activité';
                   this.pieChartData.chart.marginRight = -70;
                   this.pieChartData.title.text = this.sumTotalActivite ?  this.sumTotalActivite.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '';
                   this.pieChartData.title.x = 40;
                   value.data.forEach((element) => {
                       this.pieChartData.series[0].data.push({name: element[0],y:(Number(element[1]/this.sumTotalActivite)*100)});
                   });
                   break;
                   case 'transaction' :
                       this.sumTotalTransaction= this.getTotal(value.data, 4);
                   this.pieChartData.series[0].name = 'Transaction par agence';
                   this.pieChartData.chart.marginRight = -70;
                   this.pieChartData.title.text = this.sumTotalTransaction ?  this.sumTotalTransaction.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '';
                   this.pieChartData.title.x = 40;
                   value.data.forEach((element) => {
                       this.pieChartData.series[0].data.push({name: element[0],y:(Number(element[4]/this.sumTotalTransaction)*100)});
                   });
                   break;
                   case 'repartition' :
                       this.sumTotalRepartition= this.getTotal(value.data, 3);
                   this.pieChartData.series[0].name = 'Transaction par agence';
                   this.pieChartData.chart.marginRight = -70;
                   this.pieChartData.title.text = this.sumTotalRepartition ?  this.sumTotalRepartition.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '';
                   this.pieChartData.title.x = 40;
                   value.data.forEach((element) => {
                       this.pieChartData.series[0].data.push({name: element[0],y:(Number(element[3]/this.sumTotalRepartition)*100)});
                   });
                   break;
               case 'events':
                   this.pieChartData.title.text =  'Anomalies';
                   this.type = 'events';
                   this.pieChartData.chart.marginRight = 220;
                   this.pieChartData.title.style.fontSize =  '0.7vw';
                   this.pieChartData.title.style.color = '#fff';
                   this.pieChartData.title.x = -110;
                   this.pieChartData.title.y = 5;
                   this.pieChartData.legend =   {
                   enabled: true,
                       itemStyle:{color: '#fff'},
               };
                   value.data.forEach((element) => {
                       const key = Object.keys(element);
                       this.pieChartData.series[0].data.push({name: element.titre,y:(Number(element[key[1]]))});
                   });
                   break;
               default:
                   break;
           }
           this.chartUpdateFlag = true;
       }
    }

    get data(){
        return this._data;
    }

    getTotal(tab: any, indexToSum: number) {
       let total = 0;
        tab.forEach((element) => {
            total += Number(element[indexToSum]);
        });

        return total
    }
}
