import {Component, Input, OnInit} from '@angular/core';
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-consommation',
  templateUrl: './consommation.component.html',
})
export class ConsommationComponent implements OnInit {

  compteurs: any;

  loading = false;

  type = 'consommation';

  periode = 'jour';

  legend;

  subscription!: Subscription;

  netSubs!: Subscription;
  tempSubscription!: Subscription;
  @Input() display = true;
  date: any;
  perimeterType = 'global';
  first = true;
  perimeterCode ='global';
  dateDebut;
  dateFin;
  dateConsommation: string;


  constructor(
      private service: MainScreenService,
      private store: Store<any>,
      private datePipe :DatePipe) {}

  ngOnInit(): void {
    this.tempSubscription = this.store
        ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
    this.netSubs = this.store.pipe(select('header_store'))?.subscribe(this.preSetAgency.bind(this));
  }


  preSetAgency(state: any) {
    this.perimeterType = state.type;
    this.perimeterCode = state.code;
      this.getConsoData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin);
      this.first = false;
  }

    getDateConsommation(){
        switch (this.periode) {
            case 'intervalle' :
                this.dateConsommation= `Date: ${this.datePipe.transform(new Date(this.dateDebut), "dd/MM/yy")} au ${this.datePipe.transform(new Date(this.dateFin), "dd/MM/yy")}`
                break;
            case 'mois':
                this.dateConsommation = `Date: ${this.datePipe.transform(new Date(this.date), "MMMM yyyy", "fr-FR")}`;
                break;
            case '30_derniers_jours':
                this.dateConsommation =`30 derniers jours`
                break;
            default:
                this.dateConsommation= `Date: ${this.datePipe.transform(new Date(this.date), "dd/MM/yy")}`;
                break;
        }
    }

  preSetDate(state: any) {
     this.date= state.selected_date;
     this.dateDebut = state.start_date;
     this.dateFin = state.end_date;
     this.periode = state.period;
     this.getDateConsommation();
    if(!this.first){
     this.getConsoData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin);
   }
  }


  getConsoData(code, type, date, date_debut, date_fin) {

    this.loading = true;
    this.service.getConso(this.type, type, code, this.periode, this.legend, date, date_debut, date_fin).subscribe(
        (data: any) => {
          this.loading = false;
          this.compteurs = {compteurs: data, type: this.type, displayLegend: this.display};

        },
        (error) => {
          this.loading = false;
        },
    );
  }

}
