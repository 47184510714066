import {Component, OnInit} from '@angular/core';
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-coupure',
  templateUrl: './coupure.component.html',
  styleUrls: ['./coupure.component.css']
})
export class CoupureComponent implements OnInit{
  dateCoupure: string;

  constructor(
      private service: MainScreenService,
      private store: Store<any>,
      private datePipe :DatePipe) {}

  loading = false;

  compteurs;

  threeOff = [];

  dataOff = [];

  dataCoupure;

  type = 'coupure';

  periode = 'jour';

  date: any;
  perimeterType = 'global';
  first = true;
  perimeterCode ='global';
  dateDebut;
  dateFin;

  netSubs!: Subscription;
  tempSubscription!: Subscription;

  ngOnInit(): void {
    this.tempSubscription = this.store
        ?.select('temp_navigation')?.subscribe(this.preSetDate.bind(this));
    this.netSubs = this.store.pipe(select('header_store'))?.subscribe(this.preSetAgency.bind(this));
  }


  preSetAgency(state: any) {
    this.perimeterType = state.type;
    this.perimeterCode = state.code;
    this.getCoupureData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin);
    this.first = false;
  }

  getDateCoupure(){
    if(this.periode === 'intervalle'){
      this.dateCoupure= `Date: ${this.datePipe.transform(new Date(this.dateDebut), "dd/MM/yy")} au ${this.datePipe.transform(new Date(this.dateFin), "dd/MM/yy")}`
    }
    else {
      this.dateCoupure = `Date: ${this.datePipe.transform(new Date(this.date), "dd/MM/yy")}`

    }
  }

  preSetDate(state: any) {
    this.date = state.selected_date;
    this.dateDebut = state.start_date;
    this.dateFin = state.end_date;
    this.periode = state.period;
    this.getDateCoupure();
    if(!this.first){
      this.getCoupureData(this.perimeterCode, this.perimeterType, this.date, this.dateDebut, this.dateFin);
    }
  }


  getCoupureData(code, type, date, date_debut, date_fin) {
    const todayDate = new Date();
    const yesterdayDate = new Date();
    const todaysDayOfMonth = todayDate.getDate();
    yesterdayDate.setDate(todaysDayOfMonth - 1);
    this.dataOff = [];
    this.threeOff = [];
    this.loading = true;
    this.service.getCoupure(type, code, this.periode, date, date_debut, date_fin).subscribe(
        (data) => {
          this.dataCoupure = data.coordonnees;
          this.dataCoupure.forEach((line: any, i: any) => {
            if (this.dataCoupure[i][4] === 'GR4' || this.dataCoupure[i][4] === 'GR5'){
              this.threeOff.push([this.dataCoupure[i][0], this.dataCoupure[i][1], this.dataCoupure[i][2], this.dataCoupure[i][3]]);
            } else {
              this.dataOff.push([this.dataCoupure[i][0], this.dataCoupure[i][1], this.dataCoupure[i][2], this.dataCoupure[i][3]]);
            }
          });
          this.loading = false;
          this.compteurs = {compteurs: data, threeOff: this.threeOff, dataOff: this.dataOff, type: this.type, displayLegend: true};
        },
        (error) => {
          this.loading = false;
        },
    );
  }

}
