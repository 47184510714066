import {NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { BrowserModule } from '@angular/platform-browser'

import { ComponentsModule } from './components/components.module'
import { AppComponent } from './app.component'
import { MainScreenService } from './services/main-screen/main-screen.service'
import { ErrorHandlerService } from './services/error-handler/error-handler.service'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {DatePipe, registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HomeComponent} from "./pages/home/home.component";
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbDialogModule,
  NbGlobalLogicalPosition, NbInputModule,
  NbLayoutModule, NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule
} from "@nebular/theme";
import {NbEvaIconsModule} from "@nebular/eva-icons";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {StoreModule} from "@ngrx/store";
import {SelectAgencyReducer} from "./pages/home/reducers/header.reducer";
import {tempNavReducer} from "./pages/home/reducers/temporal-navigation.reducer";
import { LoginComponent } from './pages/login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { LoginForgotComponent } from './pages/login-forgot/login-forgot.component';
import {AuthService} from "./services/auth/auth.service";
import {HttpErrorInterceptor} from "./services/interceptors/http-error.interceptor.service";
import {BasicAuthInterceptor} from "./services/interceptors/basic-http.interceptor.service";
import {NgxPermissionsModule} from "ngx-permissions";
import {HomeModule} from "./pages/home/home.module";

registerLocaleData(localeFr, 'fr');

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-forgot',
    component: LoginForgotComponent,
  },
  {
    path: 'home',
    loadChildren: () =>
        import('./pages/home/home.module').then((m) => m.HomeModule),
  },
]

@NgModule({
  declarations: [
      AppComponent,
      HomeComponent,
      LoginComponent,
      LoginForgotComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forRoot(),
    NbDialogModule.forRoot({
      hasBackdrop: true,
      closeOnBackdropClick: false,
      hasScroll: true,
    }),
    NbEvaIconsModule,
    NbInputModule,
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbThemeModule.forRoot({ name: 'default' }),
    NbToastrModule.forRoot({
      position: NbGlobalLogicalPosition.TOP_END,
    }),
      NbThemeModule.forRoot({ name: 'default' }),RouterModule, NbDialogModule.forRoot({
    hasBackdrop: true,
    closeOnBackdropClick: false,
    hasScroll: true,
  }),
    StoreModule.forRoot({
      // @ts-ignore
      header_store: SelectAgencyReducer,
      // @ts-ignore
      temp_navigation: tempNavReducer,
    }),
    RouterModule.forRoot(routes), ComponentsModule, HttpClientModule, HttpClientModule, HomeModule, NgbModule, NbLayoutModule],
  providers: [
    ErrorHandlerService,
    MainScreenService,
      AuthService,
    ErrorHandlerService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
