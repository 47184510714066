import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import {LeafletMarkerClusterModule} from "@asymmetrik/ngx-leaflet-markercluster";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import { HeaderComponent } from './components/header/header.component';
import { CarteComponent } from './components/carte/carte.component';
import { SelecterComponent } from './components/selecter/selecter.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import { GraphiquesComponent } from './components/graphiques/graphiques.component';
import { QualiteTensionsComponent } from './components/qualite-tensions/qualite-tensions.component';
import { GeographiqueComponent } from './components/geographique/geographique.component';
import { LegendComponent } from './components/legend/legend.component';
import { ConsommationComponent } from './components/consommation/consommation.component';
import { RouterModule } from '@angular/router';
import {NgxLoadingModule} from "ngx-loading";
import {
    NbButtonGroupModule,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
    NbDialogModule, NbIconModule,
    NbLayoutModule, NbSpinnerModule
} from "@nebular/theme";
import { DetailsCompteurDialogComponent } from './components/details-compteur-dialog/details-compteur-dialog.component';
import {HighchartsChartModule} from "highcharts-angular";
import { CoupureComponent } from './components/coupure/coupure.component';
import { ConsommationVueGraphiqueComponent } from './components/consommation-vue-graphique/consommation-vue-graphique.component';
import { QualiteTensionsVueGraphiqueComponent } from './components/qualite-tensions-vue-graphique/qualite-tensions-vue-graphique.component';
import { CoupureVueGraphiqueComponent } from './components/coupure-vue-graphique/coupure-vue-graphique.component';
import { DonutChartComponent } from './shared/donut-chart/donut-chart.component';
import {HistogrammeverticalComponent} from "./shared/histogrammevertical/histogrammevertical.component";
import { TableauxComponent } from './shared/tableaux/tableaux.component';
import { HistogrammeverticalCourbeComponent } from './shared/histogrammevertical-courbe/histogrammevertical-courbe.component';
import { HistogrammePairComponent } from './shared/histogramme-pair/histogramme-pair.component';
import { RepartitionConsoCalendrierHorairComponent } from './components/repartition-conso-calendrier-horair/repartition-conso-calendrier-horair.component';
import { RepartitionVenteComponent } from './components/repartition-vente/repartition-vente.component';
import { ConsoParAgenceComponent } from './shared/conso-par-agence/conso-par-agence.component';
import { TransactionParMoisComponent } from './shared/transaction-par-mois/transaction-par-mois.component';
import { ConsoDetailsComponent } from './components/conso-details/conso-details.component';
import { CourbeUniqueComponent } from './shared/courbe-unique/courbe-unique.component';
import { HistogrammeTriphaseComponent } from './shared/histogramme-triphase/histogramme-triphase.component';
import { CourbeMoyenneComponent } from './shared/courbe-moyenne/courbe-moyenne.component';
import { GetStatistiqueComponent } from './components/get-statistique/get-statistique.component';
import { EvenementsComponent } from './components/evenements/evenements.component';
import { DetailsEventsComponent } from './components/details-events/details-events.component';
import { SurtensionBaisseTensionComponent } from './components/surtension-baisse-tension/surtension-baisse-tension.component';
import { AbonnementsComponent } from './components/abonnements/abonnements.component';
import {CourbeQualimetrieComponent} from "./shared/courbe-qualimetrie/courbe-qualimetrie.component";
import {DateComponent} from "./components/date/date.component";
import { CarteCoupuresComponent } from './components/carte-coupures/carte-coupures.component';

import { HistogrammeHorizontaleComponent } from './shared/histogramme-horizontale/histogramme-horizontale.component';
import { CarteTensionComponent } from './components/carte-tension/carte-tension.component';
import { DetailsPostesComponent } from './components/details-postes/details-postes.component';

@NgModule({
    declarations: [
        HeaderComponent,
        CarteComponent,
        SelecterComponent,
        GraphiquesComponent,
        QualiteTensionsComponent,
        GeographiqueComponent,
        LegendComponent,
        ConsommationComponent,
        DetailsCompteurDialogComponent,
        CoupureComponent,
        ConsommationVueGraphiqueComponent,
        QualiteTensionsVueGraphiqueComponent,
        CoupureVueGraphiqueComponent,
        HistogrammeverticalComponent,
        DonutChartComponent,
        HistogrammeverticalComponent,
        TableauxComponent,
        HistogrammeverticalCourbeComponent,
        HistogrammePairComponent,
        RepartitionVenteComponent,
        ConsoDetailsComponent,
        CourbeUniqueComponent,
        ConsoParAgenceComponent,
        TransactionParMoisComponent,
        RepartitionConsoCalendrierHorairComponent,
        HistogrammeTriphaseComponent,
        CourbeMoyenneComponent,
        GetStatistiqueComponent,
        EvenementsComponent,
        DetailsEventsComponent,
        SurtensionBaisseTensionComponent,
        AbonnementsComponent,
        CourbeQualimetrieComponent,
        DateComponent,
        CarteCoupuresComponent,
        DateComponent,
        HistogrammeHorizontaleComponent,
        CarteTensionComponent,
        DetailsPostesComponent,
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        LeafletMarkerClusterModule,
        LeafletModule,
        NgSelectModule,
        FormsModule,
        RouterModule,
        NgxLoadingModule.forRoot({}),
        NbCardModule,
        NbDialogModule.forChild(),
        NbLayoutModule,
        HighchartsChartModule,
        NbButtonGroupModule,
        NbButtonModule,
        NbDatepickerModule.forRoot(),
        NbIconModule,
        NbSpinnerModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        SelecterComponent,
        DateComponent,
    ]
})
export class HomeModule { }
