import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import * as _ from 'lodash';
import {TRANSACTION_PAR_MOIS} from "../../../../../utils/charts/courbes-option";
import * as moment from 'moment';

@Component({
    selector: 'app-transaction-par-mois',
    templateUrl: './transaction-par-mois.component.html',
    styleUrls: ['./transaction-par-mois.component.css']
})
export class TransactionParMoisComponent {

    highcharts = Highcharts;
    chartUpdateFlag = false;
    chartOptions = _.cloneDeep(TRANSACTION_PAR_MOIS);

    _data
    @Input() set data(value) {
        this.chartOptions.series[0].data = [];
        this.chartOptions.xAxis.categories = [];
        this._data = value;
        const diffMonths = this.calculateMonths(value);

        this._data.forEach((element: any[]) => {
            if(diffMonths > 3) {
                this.chartOptions.xAxis.categories.push(moment(element[0]).format('MM-yyyy'));
            }else{
                this.chartOptions.xAxis.categories.push(moment(element[0]).format('DD-MM'));
            }
            this.chartOptions.series[0].data.push(Number(element[3]));
        });
        this.chartUpdateFlag = true;
    }

    get data() {
        return this._data;
    }

    calculateMonths(data) {
        const startDate = new Date(data[0][0]);
        const endDate = new Date(data[data.length - 1][0]);
        const diffMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
        return diffMonths;
    }
}
