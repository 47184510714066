<app-legend [ngClass]="displayLeg? 'legend': 'leg'"
            [date]="dateOfData"
            [titre]="titre"
            [isEvent]="isEvent"
            (refresh)="refresh($event)" [legend]="legend">

</app-legend>

<div class="map"
     leaflet
     [leafletOptions]="mapOptions"
     [leafletMarkerCluster]="markerClusterData"
     [leafletMarkerClusterOptions]="markerClusterOptions"
>
</div>
