import { Component } from '@angular/core';

@Component({
  selector: 'app-graphiques',
  templateUrl: './graphiques.component.html',
  styleUrls: ['./graphiques.component.scss']
})
export class GraphiquesComponent {

}
