import {Component, Input} from '@angular/core';
import * as Highcharts from "highcharts";
import {HISTOGRAMME_OPTIONS} from "../../../../../utils/charts/column-chart-options";
import * as _ from 'lodash';

@Component({
  selector: 'app-histogrammevertical',
  templateUrl: './histogrammevertical.component.html',
  styleUrls: ['./histogrammevertical.component.css']
})
export class HistogrammeverticalComponent {

    highcharts = Highcharts;
    chartOptions =  _.cloneDeep(HISTOGRAMME_OPTIONS);
    _data;
    chartUpdateFlag = false;

    @Input() set data(value){
        this.chartOptions.series[0].data = [];
        this.chartOptions.xAxis.categories = [];
        this._data = value.data;
        this.chartOptions.series[0].name = 'Conso par agence';
        if(value.type === 'kWh'){
            this.chartOptions.colors = ['#01ABAE'];
            this._data.forEach((element) => {
                this.chartOptions.xAxis.categories.push(element[0]);
                this.chartOptions.series[0].data.push(Number(element[2]));
            });
        } else if(value.type === 'coupures'){
            this.chartOptions.series[0].name = 'Durée';
            this.chartOptions.colors = ['#D0C000'];
            this.chartOptions.plotOptions.series.borderColor = '#D0C000';
            this.chartOptions.xAxis.categories = value.mois;
            this.chartOptions.series[0].data = value.moy;
        }
        else {
            this._data.forEach((element) => {
                this.chartOptions.colors = ['#47C471'];
                this.chartOptions.xAxis.categories.push(element[0]);
                this.chartOptions.series[0].data.push(Number(element[3]));
            });
        }

        this.chartUpdateFlag = true;
    }

    get data() {
        return this._data;
    }


}
