
export  const HISTOGRAMME_OPTIONS = {

  chart:{

    type:'column',
    backgroundColor: 'transparent',
  },
  credits: {
    enabled: false,
  },

  colors: [],
  title:{
    text: '',
    margin: 50,

    style: {
      color: '#FFF',
      fontWeight: 'bold',
    }

  },

  xAxis:{
    categories: [],
    lineWidth: 1,
    labels: {
      style: {
        color: '#FFF',
        fontSize:  '0.7rem'
      }
    },
  },
  yAxis: {
    gridLineWidth: 0,
    title: '',
    lineWidth: 1,
    labels: {
      style: {
        color: '#FFF',
        fontSize:  '0.8rem'
      }
    },
  },
  plotOptions: {
    series: {
      borderColor: 'rgba(11, 162, 246, 0.6)'
    },
    column: {}
  },
  legend: {
    enabled: true, itemStyle: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '0.8vw',
    },
  },
  series: [
    {
      name: '',
      data: [],
      dataLabels: {
        enabled: false,

      },


    },

  ]


}


