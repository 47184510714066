import { Component } from '@angular/core';
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {MainScreenService} from "../../../../services/main-screen/main-screen.service";

@Component({
  selector: 'app-abonnements',
  templateUrl: './abonnements.component.html',
  styleUrls: ['./abonnements.component.css']
})
export class AbonnementsComponent {

  constructor(private service: MainScreenService, private store: Store<any>,) {}

  loading = false;

  compteurs;

  type = 'abonnement';

  periode = 'hier';

  subscription!: Subscription;

  netSubs!: Subscription;

  ngOnInit(): void {
    this.netSubs = this.store.pipe(select('header_store'))?.subscribe(this.preSetAgency.bind(this));
  }

  preSetAgency(state: any) {
    this.getAbonnementData(state.code, state.type);
  }

  getAbonnementData(code, type) {
    this.loading = true;
    this.service.getAbonnements(type, code, this.periode).subscribe(
        (data) => {
          this.loading = false;
          this.compteurs = {compteurs: data, type: this.type, displayLegend: true};
        },
        (error) => {
          this.loading = false;
        },
    );
  }

}
