<app-legend [ngClass]="displayLeg? 'legend': 'leg'" [titre]="titre" [date]="dateOfData" (refresh)="refresh($event)" [legend]="legend"></app-legend>
<ngx-loading
        [show]="loading"
></ngx-loading>
<div
        class="map"
        leaflet
        id="map"
>
</div>
